import { axiosInstance } from './config';  
  
export const submitChatCompletion = async (prompt, history, dataSourceId, organizationId) => {  
    try {  
        const response = await axiosInstance.post(`/api/completion`, {  
            prompt,  
            history,  
            dataSourceId: dataSourceId ?? 0,  
            organizationId,  
        });  
        return response.data;  
    } catch (error) {  
        console.error('Error submitting chat completion:', error);  
        throw error;  
    }  
};  
