import React, { useEffect, useState } from 'react';  
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';  
import Header from '../Header/Header';  
import Sidebar from '../Sidebar/Sidebar';  
import AppRoutes from '../Routes/Routes';  
import ProfilePopup from '../Profile/ProfilePopup';  
import SplashPage from '../../pages/Splash/SplashPage';  
import { getStoredOrg } from '../../services/organization';  
import './Layout.css';  
  
const Layout = ({  
  user,  
  handleLogout,  
  setUser,  
}) => {  
  const [activeMenu, setActiveMenu] = useState('Chat');  
  const [menuVisible, setMenuVisible] = useState(false);  
  const [selectedOrg, setSelectedOrg] = useState(getStoredOrg());  
  const [isProfileOpen, setIsProfileOpen] = useState(false);  
  const location = useLocation();  
  
  useEffect(() => {  
    if (user) {  
      const storedOrg = getStoredOrg();  
      const org = storedOrg && user.organizations.some(org => org.id === storedOrg.id)  
        ? storedOrg  
        : user.organizations[0];  
      setSelectedOrg(org);  
      localStorage.setItem('selectedOrg', JSON.stringify(org));  
    }  
  }, [user]);  
  
  useEffect(() => {  
    const pathToMenu = {  
      '/chat': 'Chat',  
      '/datasources': 'DataSources',  
      '/teams': 'Teams',  
      '/insights': 'Insights',  
      '/guide': 'Help',  
      '/support': 'Support',  
      '/users': 'Users',  
      '/organizations': 'Organizations',  
    };  
    setActiveMenu(pathToMenu[location.pathname] || '');  
  }, [location]);  
  
  if (user.organizations.length === 0 && !user.isSystemAdmin) {  
    return (  
      <>  
        <Header  
          handleLogout={handleLogout}  
          menuVisible={menuVisible}  
          setMenuVisible={setMenuVisible}  
          user={user}  
          setProfileOpen={setIsProfileOpen}  
          selectedOrg={selectedOrg}  
          setSelectedOrg={setSelectedOrg}  
          organizations={user.organizations}  
        />  
        <SplashPage message="You are not part of any organizations yet. Please contact your system administrator." />
        {isProfileOpen && <ProfilePopup user={user} onClose={() => setIsProfileOpen(false)} />}  
      </>  
    );  
  }  
  
  return (  
    <>  
      <Header  
        handleLogout={handleLogout}  
        menuVisible={menuVisible}  
        setMenuVisible={setMenuVisible}  
        user={user}  
        setProfileOpen={setIsProfileOpen}  
        selectedOrg={selectedOrg}  
        setSelectedOrg={setSelectedOrg}  
        organizations={user.organizations}  
      />  
      <div className="main-content">  
        {user.canViewSidebar && (  
          <Sidebar  
            activeMenu={activeMenu}  
            setActiveMenu={setActiveMenu}  
            user={user}  
            organizations={user.organizations}  
          />  
        )}  
        <div className={`content-container ${user.canViewSidebar ? '' : 'full-width'}`}>  
          <AppRoutes  
            user={user}  
            dataSources={user?.dataSources || []}  
            selectedOrg={selectedOrg}  
            setUser={setUser}  
          />  
        </div>  
      </div>  
      {isProfileOpen && <ProfilePopup user={user} onClose={() => setIsProfileOpen(false)} />}  
    </>  
  );  
};  
  
export default Layout;  
