import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUsers, deleteUser } from "../../services/user";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Table } from "@uitk/react";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import TableActionsColumn from "../../components/Common/TableActionsColumn";

const UsersContentPage = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();

  const fetchAllUsers = async () => {
    const users = await fetchUsers();
    setUsers(users);
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleEditClick = (username) => {
    navigate(`/users/edit/${username}`);
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    await handleDelete(userToDelete.username);
    setIsDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDelete = async (username) => {
    await deleteUser(username);
    setUsers(users.filter((user) => user.username !== username));
  };

  // Prepare table data by mapping users to include full name
  const tableData = users.map((user) => ({
    username: user.username,
    fullName: `${user.firstName} ${user.lastName}`,
  }));

  // Define configuration for the uitk Table component
  const usersTableDescription = "Users can be directly added to the system, as an alternative to being added to Secure Groups.";
  const tableConfig = {
    columns: [
      { label: "Username", key: "username", sortColumn: true },
      { label: "Full Name", key: "fullName", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => (
          <TableActionsColumn
            id={rowData.username}
            name={rowData.fullName}
            onEdit={() => handleEditClick(rowData.username)}
            onDelete={() =>
              handleDeleteClick({
                username: rowData.username,
                fullName: rowData.fullName,
              })
            }
          />
        ),
      },
    ],
    sort: true,
    caption: {
      headerText: "Users",
      descriptionText: usersTableDescription,
      // Override the caption description to prevent it from changing when user sorts a column
      getSortCaptionDescription: () => usersTableDescription,
    },
    sticky: {
      maxHeight: "70vh",
      headerSticky: true,
    },
  };

  return (
    <div className="page-container">
      <div className="page-upper-body">
        <Button onPress={() => navigate("/users/new")}>Add new</Button>
      </div>
      <div className="page-body">
        <Table data={tableData} config={tableConfig} noDataMessage="No users available." className="table-compact" />
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to delete User '${userToDelete.username}'? This action cannot be undone.`}
          title="Delete User?"
        />
      )}
    </div>
  );
};

export default UsersContentPage;
