import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchTeams, deleteTeam } from "../../services/team";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Table } from "@uitk/react";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import TableActionsColumn from "../../components/Common/TableActionsColumn";

const TeamsContentPage = ({ selectedOrg }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  const fetchAllTeams = async () => {
    try {
      const teams = await fetchTeams(selectedOrg.id);
      setTeams(teams);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  useEffect(() => {
    if (selectedOrg.id) {
      fetchAllTeams();
    }
  }, [selectedOrg.id]);

  const handleEditClick = (id) => {
    navigate(`/teams/edit/${id}`);
  };

  const handleDeleteClick = (team) => {
    setTeamToDelete(team);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    await handleDelete(teamToDelete.id);
    setIsDeleteDialogOpen(false);
    setTeamToDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      await deleteTeam(selectedOrg.id, id);
      setTeams(teams.filter((team) => team.id !== id));
    } catch (error) {
      console.error("Error deleting team:", error);
      // Optionally, show an error message to the user
    }
  };

  // Prepare table data by mapping teams to include numberOfUsers
  const tableData = teams.map((team) => ({
    id: team.id,
    name: team.name,
    numberOfUsers: team.users.length,
  }));

  // Define configuration for the uitk Table component
  const teamsTableDescription = "Teams are groups of users that help simplify the management of access to data sources.";
  const tableConfig = {
    columns: [
      { label: "Name", key: "name", sortColumn: true },
      { label: "Number of Users", key: "numberOfUsers", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => <TableActionsColumn id={rowData.id} name={rowData.name} onEdit={handleEditClick} onDelete={handleDeleteClick} />,
      },
    ],
    sort: true,
    caption: {
      headerText: "Teams",
      descriptionText: teamsTableDescription,
      // Override the caption description to prevent it from changing when user sorts a column
      getSortCaptionDescription: () => teamsTableDescription,
    },
    sticky: {
      maxHeight: "70vh",
      headerSticky: true,
    },
  };

  return (
    <div className="page-container">
      <div className="page-upper-body">
        <Button onPress={() => navigate("/teams/new")}>Add new</Button>
      </div>
      <div className="page-body">
        <Table data={tableData} config={tableConfig} noDataMessage="No teams available." className="table-compact" />
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to delete Team '${teamToDelete.name}'? This action cannot be undone.`}
          title="Delete Team?"
        />
      )}
    </div>
  );
};

export default TeamsContentPage;
