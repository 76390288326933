// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-message {  
  display: flex;  
  justify-content: center;  
}  
  
.system-message p {  
  border-radius: 5px;  
  font-style: italic;  
  font-size: 14px;
  color: #7a7a7a;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/Chat/SystemMessage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".system-message {  \r\n  display: flex;  \r\n  justify-content: center;  \r\n}  \r\n  \r\n.system-message p {  \r\n  border-radius: 5px;  \r\n  font-style: italic;  \r\n  font-size: 14px;\r\n  color: #7a7a7a;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
