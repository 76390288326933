import React, { useState, useCallback } from "react";
import { FaTrash, FaDownload, FaSpinner } from "react-icons/fa";
import "./DatasetFiles.css";
import {
  fetchDocument,
  fetchDataSourceFileIndexDocuments,
} from "../../services/datasource";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import DatasetFileIndexDocumentListPopup from "./DatasetFileIndexDocumentListPopup";

const DatasetFiles = ({
  dataSourceId,
  fileMetadata,
  isLoading,
  handleFileAttachmentsChange,
  handleFileAttachmentsRemove,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  const documentIntelligenceSupportedExtensions = [
    ".pdf",
    ".jpeg",
    ".jpg",
    ".png",
    ".bmp",
    ".tiff",
    ".heif",
    ".docx",
    ".xlsx",
    ".pptx",
    ".html",
  ];

  const getColumnValue = (fileName) => {
    const extension = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
    return documentIntelligenceSupportedExtensions.includes(extension)
      ? "DocumentIntelligence"
      : "AiSearchIndexer";
  };

  const onFileAttachmentsChange = (e) => {
    const newFileAttachments = Array.from(e.target.files);
    setNewFiles((prevFiles) => [...prevFiles, ...newFileAttachments]);
    handleFileAttachmentsChange(newFileAttachments);
  };

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);
      const newFileAttachments = Array.from(e.dataTransfer.files);
      setNewFiles((prevFiles) => [...prevFiles, ...newFileAttachments]);
      handleFileAttachmentsChange(newFileAttachments);
    },
    [handleFileAttachmentsChange]
  );

  const handleFileDownloadClick = async (fileName) => {
    try {
      const fileBlob = await fetchDocument(dataSourceId, fileName);
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const handleFileAttachmentsRemoveClick = (file, index) => {
    setFileToDelete({ file, index });
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    handleFileAttachmentsRemove(fileToDelete.index);
    setIsDeleteDialogOpen(false);
    setFileToDelete(null);
  };

  const handleViewClick = async (fileName) => {
    try {
      const data = await fetchDataSourceFileIndexDocuments(
        dataSourceId,
        fileName
      );
      setDocuments(data);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  return (
    <div className="file-container">
      <div className="section-title">Files</div>
      <div
        className={`file-upload-section ${isDragging ? "dragging" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("file-input").click()}
      >
        <p>Drag & drop your files here or click to browse</p>
        <input
          id="file-input"
          type="file"
          multiple
          onChange={onFileAttachmentsChange}
          style={{ display: "none" }}
        />
      </div>
      <div className="file-list">
        {isLoading ? (
          <div className="loading-spinner">
            Loading...
            <br />
            <FaSpinner className="icon-spin" />
          </div>
        ) : (
          <table className="file-table">
            <thead>
              <tr>
                <th>File Name</th>
                <th style={{ width: "150px" }}>Index Documents</th>
                <th style={{ width: "150px" }}>Size</th>
                <th style={{ width: "150px" }}>Uploaded By</th>
                <th style={{ width: "200px" }}>Upload Date</th>
                <th style={{ width: "200px" }}>Indexing Strategy</th>
                <th style={{ width: "100px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fileMetadata.map((file, index) => (
                <tr key={index}>
                  <td>
                    <span>{file.fileName}</span>
                  </td>
                  <td>
                    <button onClick={() => handleViewClick(file.fileName)}>
                      View
                    </button>
                  </td>
                  <td>
                    <span>{(file.sizeInBytes / 1024).toFixed(2)} KB</span>
                  </td>
                  <td>
                    <span>{file.uploadedByUser}</span>
                  </td>
                  <td>
                    <span>{new Date(file.uploadDateUtc).toLocaleString()}</span>
                  </td>
                  <td>
                    <span>{getColumnValue(file.fileName)}</span>
                  </td>
                  <td>
                    <span
                      className="action-button"
                      onClick={() => handleFileDownloadClick(file.fileName)}
                    >
                      <FaDownload />
                    </span>
                    <span
                      className="action-button"
                      onClick={() =>
                        handleFileAttachmentsRemoveClick(file, index)
                      }
                    >
                      <FaTrash />
                    </span>
                  </td>
                </tr>
              ))}
              {newFiles.map((file, index) => (
                <tr key={index + fileMetadata.length}>
                  <td>
                    <span>{file.name}</span>
                  </td>
                  <td></td>
                  <td>
                    <span>{(file.size / 1024).toFixed(2)} KB</span>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <span>{getColumnValue(file.name)}</span>
                  </td>
                  <td>
                    <span
                      className="action-button"
                      onClick={() =>
                        handleFileAttachmentsRemoveClick(
                          file,
                          index + fileMetadata.length
                        )
                      }
                    >
                      <FaTrash />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to remove the file '${fileToDelete?.file?.fileName}'? The removal and re-indexing will be performed once the Data Source is saved. If you don't save the Data Source, the file will not be removed.`}
          title="Remove File?"
        />
      )}
      <DatasetFileIndexDocumentListPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        documents={documents}
      />
    </div>
  );
};

export default DatasetFiles;
