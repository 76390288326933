import React, { useState, useEffect } from 'react';  
import './DataSourceFileListPopup.css';  
import { FaSpinner, FaTimes } from 'react-icons/fa';  
import { fetchDataSourceFileMetadata } from '../../services/datasource';  
  
const DataSourceFileListPopup = ({ dataSourceId, isOpen, onClose }) => {  
  const [isLoading, setIsLoading] = useState(false);  
  const [fileList, setFileList] = useState([]);  
  
  useEffect(() => {  
    if (isOpen) {  
      fetchFileList();  
    }  
  }, [isOpen]);  
  
  const fetchFileList = async () => {  
    setIsLoading(true);  
    try {  
      const files = await fetchDataSourceFileMetadata(dataSourceId);  
      setFileList(files);  
    } catch (error) {  
      console.error('Error fetching file metadata:', error);  
    } finally {  
      setIsLoading(false);  
    }  
  };  
  
  if (!isOpen) return null;  
  
  return (  
    <div className="popup-overlay">  
      <div className="datasource-filelist-popup-content">  
        <button className="close-button" onClick={onClose}>  
          <FaTimes />  
        </button>  
        {isLoading ? (  
          <FaSpinner className="file-list-icon-spin" />  
        ) : (  
          <div className="file-list">  
            <table className="file-table">  
              <thead>  
                <tr>  
                  <th>File Name</th>  
                  <th>Associated URL</th>  
                </tr>  
              </thead>  
              <tbody>  
                {fileList.map((file, index) => (  
                  <tr key={index}>  
                    <td>{file.fileName}</td>  
                    <td>{file.fileSourceDisplay}</td>  
                  </tr>  
                ))}  
              </tbody>  
            </table>  
          </div>  
        )}  
      </div>  
    </div>  
  );  
};  
  
export default DataSourceFileListPopup;  
