import React, { useRef, useEffect, useState } from "react";
import ChatMessage from "./ChatMessage";
import "./ChatWindow.css";
import { Button } from "@uitk/react";
import { FaExclamationTriangle } from "react-icons/fa";

const ChatWindow = ({ messages = [], handleSubmit, handleReSubmit, prompt = "", setPrompt, isTyping }) => {
  const chatWindowRef = useRef(null);
  const [lastUserMessageCount, setLastUserMessageCount] = useState(0);

  const smoothScroll = (element, target, duration) => {
    const start = element.scrollTop;
    const change = target - start;
    const increment = 20;
    let currentTime = 0;

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  useEffect(() => {
    const userMessages = messages.filter((message) => message.role === "user").length;
    if (userMessages !== lastUserMessageCount) {
      // Scroll to bottom for user messages
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
      setLastUserMessageCount(userMessages);
    }
  }, [messages, lastUserMessageCount]);

  useEffect(() => {
    if (!isTyping) {
      const botMessages = messages.filter((message) => message.role === "assistant");
      if (botMessages.length > 0 && chatWindowRef.current) {
        const lastBotMessage = chatWindowRef.current.querySelector(".bot-message:last-child");
        if (lastBotMessage) {
          smoothScroll(chatWindowRef.current, lastBotMessage.offsetTop - 150, 500);
        }
      }
    }
  }, [isTyping, messages]);

  useEffect(() => {
    document.querySelector(".typing-container > form > input").focus();
  }, [prompt]);

  return (
    <div className="chat-container">
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            role={message.role}
            content={String(message.content)}
            sources={message.sources}
            dataSourceId={message.dataSourceId}
            isTyping={message.isTyping}
            handleReSubmit={handleReSubmit}
          />
        ))}
      </div>
      <div className="warning-container">
        <FaExclamationTriangle className="warning-icon" />
        <div className="warning-text">
          <strong>WARNING</strong>
          Results are for evaluation only. AI can hallucinate – results can be incomplete or wrong. Do not use this for decision making, and do not
          take results outside of the application for internal or external use.
        </div>
      </div>
      <div className="typing-container">
        <form onSubmit={handleSubmit}>
          <input type="text" value={prompt} onChange={(e) => setPrompt(e)} placeholder="Type your message..." />
          <Button onPress={handleSubmit} disabled={!prompt.trim()}>
            Send
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;
