// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {  
  font-family: 'Roboto', sans-serif;  
  background-color: #f0f0f0;  
  margin: 0;  
  padding: 0;  
  display: flex;  
  height: 100vh;  
  box-sizing: border-box;  
  color: #333;  
}  

.app-container {  
  display: flex;  
  flex-direction: column;  
  width: 100%;  
  height: 100%;  
}

.main-content {  
  display: flex;  
  flex-direction: row;  
  height: 100%;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["body {  \r\n  font-family: 'Roboto', sans-serif;  \r\n  background-color: #f0f0f0;  \r\n  margin: 0;  \r\n  padding: 0;  \r\n  display: flex;  \r\n  height: 100vh;  \r\n  box-sizing: border-box;  \r\n  color: #333;  \r\n}  \r\n\r\n.app-container {  \r\n  display: flex;  \r\n  flex-direction: column;  \r\n  width: 100%;  \r\n  height: 100%;  \r\n}\r\n\r\n.main-content {  \r\n  display: flex;  \r\n  flex-direction: row;  \r\n  height: 100%;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
