// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-control-section {  
  width: 100% !important;  
  margin: 0 auto;  
}  
  
.access-control-subsection {  
  padding: 10px; 
  border: 1px solid #ddd; 
  border-radius: 5px; 
  background-color: #f9f9f9;
}  
  
.access-control-subsection-team {  
  margin-top: 15px; 
  padding: 10px; 
  border: 1px solid #ddd; 
  border-radius: 5px; 
  background-color: #f9f9f9; 
}  
    
.permission-container {  
  display: flex;  
  align-items: center;  
  justify-content: flex-end;  
  gap: 50px;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DataSourceAccessControlTab.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;AACX","sourcesContent":[".access-control-section {  \r\n  width: 100% !important;  \r\n  margin: 0 auto;  \r\n}  \r\n  \r\n.access-control-subsection {  \r\n  padding: 10px; \r\n  border: 1px solid #ddd; \r\n  border-radius: 5px; \r\n  background-color: #f9f9f9;\r\n}  \r\n  \r\n.access-control-subsection-team {  \r\n  margin-top: 15px; \r\n  padding: 10px; \r\n  border: 1px solid #ddd; \r\n  border-radius: 5px; \r\n  background-color: #f9f9f9; \r\n}  \r\n    \r\n.permission-container {  \r\n  display: flex;  \r\n  align-items: center;  \r\n  justify-content: flex-end;  \r\n  gap: 50px;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
