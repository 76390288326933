// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.github-container {  
  width: 100%;  
}  
  
.add-repository {  
  display: flex;  
  justify-content: flex-end;  
  margin-bottom: 10px;  
}  

.repositories-table {  
  width: 100%;  
  border-collapse: collapse;  
  margin-top: 10px;  
}  
  
.repositories-table th,  
.repositories-table td {  
  border: 1px solid #ddd;  
  padding: 8px;  
  text-align: left;  
}  
  
.repositories-table th {  
  background-color: #fafafa;  
  color: #333;  
}  
  
.repositories-table td {  
  background-color: #ffffff;  
}  
  
.repository-url {  
  word-break: break-all;  
}  

.open-icon {  
  cursor: pointer;  
  font-size: 14px;  
  color: #888888;  
}  `, "",{"version":3,"sources":["webpack://./src/components/DataSources/DatasetGitHub.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB","sourcesContent":[".github-container {  \r\n  width: 100%;  \r\n}  \r\n  \r\n.add-repository {  \r\n  display: flex;  \r\n  justify-content: flex-end;  \r\n  margin-bottom: 10px;  \r\n}  \r\n\r\n.repositories-table {  \r\n  width: 100%;  \r\n  border-collapse: collapse;  \r\n  margin-top: 10px;  \r\n}  \r\n  \r\n.repositories-table th,  \r\n.repositories-table td {  \r\n  border: 1px solid #ddd;  \r\n  padding: 8px;  \r\n  text-align: left;  \r\n}  \r\n  \r\n.repositories-table th {  \r\n  background-color: #fafafa;  \r\n  color: #333;  \r\n}  \r\n  \r\n.repositories-table td {  \r\n  background-color: #ffffff;  \r\n}  \r\n  \r\n.repository-url {  \r\n  word-break: break-all;  \r\n}  \r\n\r\n.open-icon {  \r\n  cursor: pointer;  \r\n  font-size: 14px;  \r\n  color: #888888;  \r\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
