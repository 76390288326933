import React from 'react';  
import ReactMarkdown from 'react-markdown';  
import './UserMessage.css';  
import { FaUser } from 'react-icons/fa';  
  
const UserMessage = ({ content }) => {  
  return (  
    <div className="user-message">  
      <div className="user-message-content">  
        <div className="user-message-text">  
          <ReactMarkdown>{String(content)}</ReactMarkdown>  
        </div>  
        <div className="icon">  
          <FaUser />  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default UserMessage;  
