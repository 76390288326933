import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchDataSources, deleteDataSource, fetchDataSource } from "../../services/datasource";
import { fetchTeams } from "../../services/team";
import { FaSpinner, FaCog, FaCheck, FaQuestionCircle, FaExclamationCircle } from "react-icons/fa";
import "./DataSourcesContentPage.css";
import DataSourceFileListPopup from "../../components/DataSources/DataSourceFileListPopup";
import { Button, Table } from "@uitk/react";
import SplashPage from "../Splash/SplashPage";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import TableActionsColumn from "../../components/Common/TableActionsColumn";

const DataSourcesContentPage = ({ user, selectedOrg }) => {
  if (selectedOrg === undefined) {
    return <SplashPage message="This page will be available once an Organization is selected." />;
  }

  const [dataSources, setDataSources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDataSourceId, setSelectedDataSourceId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [dataSourceToDelete, setDataSourceToDelete] = useState(null);
  const navigate = useNavigate();

  const fetchDataSourcesList = async () => {
    try {
      const response = await fetchDataSources(selectedOrg.id);
      setDataSources(response);
    } catch (error) {
      console.error("Error fetching data sources:", error);
    }
  };

  useEffect(() => {
    if (selectedOrg.id) {
      fetchDataSourcesList();
    }
  }, [selectedOrg.id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (dataSources.some((source) => source.fileStatus !== "Ready")) {
        fetchDataSourcesList();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [dataSources]);

  const handleDelete = async (dataSourceId) => {
    await deleteDataSource(selectedOrg.id, dataSourceId);
    fetchDataSourcesList();
  };

  const handleEditClick = async (id) => {
    const dataSource = await fetchDataSource(id);
    const teams = await fetchTeams(selectedOrg.id);
    navigate(`/datasources/edit/${id}`, { state: { initialDataSource: dataSource, availableTeams: teams } });
  };

  const handleAddNew = async () => {
    const initialDataSource = {
      id: 0,
      name: "",
      type: "SystemDefault",
      fileMetadata: [],
      systemPrompt:
        "You are an AI assistant that helps users with questions. " +
        "Your responses are concise and you aim to keep them under 200 characters when the user question is straightforward enough. " +
        "You use the in-context data provided by the user as your primary source of information for generating responses. " +
        "If more than one document is provided, always prioritize the information from the first documents over subsequent ones.",
      users: [],
      teams: [],
      organizationId: selectedOrg.id,
    };
    const teams = await fetchTeams(selectedOrg.id);
    navigate("/datasources/new", { state: { initialDataSource, availableTeams: teams } });
  };

  const renderIndexingStatus = (status, errorMessage) => {
    switch (status) {
      case "Uploading":
        return (
          <>
            <FaSpinner className="file-status icon-spin" /> {status}
          </>
        );
      case "Indexing":
      case "Analyzing":
        return (
          <>
            <FaCog className="file-status icon-spin" /> {status}
          </>
        );
      case "Ready":
        return (
          <>
            <FaCheck className="file-status" /> {status}
          </>
        );
      case "Error":
        return (
          <>
            <FaExclamationCircle className="file-status" /> {status}: {errorMessage}
          </>
        );
      default:
        return (
          <>
            <FaQuestionCircle className="file-status" /> {status}
          </>
        );
    }
  };

  const handleFileCountClick = (dataSourceId) => {
    setSelectedDataSourceId(dataSourceId);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (dataSource) => {
    setDataSourceToDelete(dataSource);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    await handleDelete(dataSourceToDelete.id);
    setIsDeleteDialogOpen(false);
    setDataSourceToDelete(null);
  };

  // Prepare table data
  const tableData = dataSources.map((dataSource) => ({
    id: dataSource.id,
    name: dataSource.name,
    accessMode: dataSource.accessMode,
    type: dataSource.type,
    fileCount: dataSource.fileCount,
    fileStatus: renderIndexingStatus(dataSource.fileStatus, dataSource.fileErrorMessage),
  }));

  const usersTableDescription = "Data Sources are repositories of information that can be utilized by AI to generate answers for queries.";
  const tableConfig = {
    columns: [
      { label: "Name", key: "name", sortColumn: true },
      { label: "Access Control", key: "accessMode", sortColumn: true },
      {
        label: "Files",
        key: "fileCount",
        onRenderCell: (rowData) => (
          <a href="#" onClick={() => handleFileCountClick(rowData.id)}>
            {rowData.fileCount}
          </a>
        ),
        sortColumn: true,
      },
      { label: "Status", key: "fileStatus", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => <TableActionsColumn id={rowData.id} name={rowData.name} onEdit={handleEditClick} onDelete={handleDeleteClick} />,
      },
    ],
    sort: true,
    caption: {
      headerText: "Data Sources",
      descriptionText: usersTableDescription,
      // Override the caption description to prevent it from changing when user sorts a column
      getSortCaptionDescription: () => usersTableDescription,
    },
    sticky: {
      maxHeight: "70vh",
      headerSticky: true,
    },
  };

  return (
    <div className="page-container">
      <div className="page-upper-body">{user.isSystemAdmin || user.isOrganizationOwner ? <Button onPress={handleAddNew}>Add new</Button> : null}</div>
      <div className="page-body">
        <Table data={tableData} config={tableConfig} noDataMessage="No data sources available." className="table-compact" />
      </div>
      <DataSourceFileListPopup dataSourceId={selectedDataSourceId} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to delete Data Source '${dataSourceToDelete.name}'? This action cannot be undone.`}
          title="Delete Data Source?"
        />
      )}
    </div>
  );
};

export default DataSourcesContentPage;
