import React, { useEffect } from 'react';  
import { FaUserCircle, FaCogs } from 'react-icons/fa';  
import './Header.css';  
import logo from '../../assets/images/optum-logo.png';  
  
const Header = ({ handleLogout, menuVisible, setMenuVisible, user, setProfileOpen, selectedOrg, setSelectedOrg, organizations }) => {  
    const toggleMenu = () => {  
        setMenuVisible((prevVisible) => !prevVisible);  
    };  
  
    const handleOutsideClick = (event) => {  
        if (!event.target.closest('.user-profile') && menuVisible) {  
            setMenuVisible(false);  
        }  
    };  
  
    useEffect(() => {  
        document.addEventListener('click', handleOutsideClick);  
        return () => {  
            document.removeEventListener('click', handleOutsideClick);  
        };  
    }, [menuVisible]);  
  
    const handleProfileClick = (event) => {  
        event.stopPropagation();  
        setMenuVisible(false);  
        setProfileOpen(true);  
    };  
    
    const handleLogoutClick = (event) => {  
        event.stopPropagation();  
        handleLogout();  
    };  
  
    const handleOrgChange = (event) => {  
        const selectedOrganization = organizations.find(org => org.id === +event.target.value);  
        setSelectedOrg(selectedOrganization);  
    };  
  
    return (  
        <div className="header">  
            <div className="header-left">  
                <img src={logo} alt="Logo" className="header-logo" />  
                <div className="header-title">Document Searcher</div>  
            </div>  
            <div className="header-right">  
                {organizations.length > 0 && (  
                    <div className="org-dropdown-container">  
                        <select value={selectedOrg?.id || ''} onChange={handleOrgChange}>  
                            {organizations.map((org) => (  
                                <option key={org.id} value={org.id}>  
                                    {org.name}  
                                </option>  
                            ))}  
                        </select>  
                    </div>  
                )} 
                <div className="user-profile" onClick={toggleMenu}>  
                    <FaUserCircle className="profile-icon" />  
                    {menuVisible && (  
                        <div className="config-menu">  
                            <div onClick={handleProfileClick}>Profile</div>  
                            <div onClick={handleLogoutClick}>Logout</div>  
                        </div>  
                    )}  
                </div>  
            </div>  
        </div>  
    );  
};  
  
export default Header;  
