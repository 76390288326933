// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-popup {  
    position: fixed;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%);  
    z-index: 1001;  
    max-width: 90%;  
  }  
    
  .overlay {  
    position: fixed;  
    top: 0;  
    left: 0;  
    width: 100%;  
    height: 100%;  
    background-color: rgba(0, 0, 0, 0.5);  
    z-index: 1000;  
  }  
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;EACf","sourcesContent":[".notification-popup {  \r\n    position: fixed;  \r\n    top: 50%;  \r\n    left: 50%;  \r\n    transform: translate(-50%, -50%);  \r\n    z-index: 1001;  \r\n    max-width: 90%;  \r\n  }  \r\n    \r\n  .overlay {  \r\n    position: fixed;  \r\n    top: 0;  \r\n    left: 0;  \r\n    width: 100%;  \r\n    height: 100%;  \r\n    background-color: rgba(0, 0, 0, 0.5);  \r\n    z-index: 1000;  \r\n  }  \r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
