// File: src\pages\Organizations\OrganizationFormPage.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchOrganization, saveOrganization } from "../../services/organization";
import { fetchUsers } from "../../services/user";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Table } from "@uitk/react";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import TableActionsColumn from "../../components/Common/TableActionsColumn";
import "./OrganizationFormPage.css";

const OrganizationFormPage = ({ user, selectedOrg }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState({
    name: "",
    users: [],
    userGroups: [],
  });
  const [selectedUsername, setSelectedUsername] = useState("");
  const [userRole, setUserRole] = useState("Member");
  const [groupName, setGroupName] = useState("");
  const [groupRole, setGroupRole] = useState("Member");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({ type: null, identifier: null });
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const organizationData = await fetchOrganization(id);
          setOrganization(organizationData);
        } catch (error) {
          console.error("Error fetching organization:", error);
        }
      }
      try {
        const usersData = await fetchUsers();
        setAllUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      [name]: value,
    }));
  };

  const handleAddUser = () => {
    if (selectedUsername) {
      setOrganization((prevOrganization) => ({
        ...prevOrganization,
        users: [...prevOrganization.users, { username: selectedUsername, role: userRole }],
      }));
      setSelectedUsername("");
      setUserRole("Member");
    }
  };

  const handleAddGroup = () => {
    const trimmedGroupName = groupName.trim();
    if (trimmedGroupName) {
      const normalizedGroupName = trimmedGroupName.toLowerCase();
      const existingGroup = organization.userGroups.find((g) => g.groupName.toLowerCase() === normalizedGroupName);
      if (existingGroup) {
        alert("Group already added");
        return;
      }
      setOrganization((prevOrganization) => ({
        ...prevOrganization,
        userGroups: [...prevOrganization.userGroups, { groupName: trimmedGroupName, role: groupRole }],
      }));
      setGroupName("");
      setGroupRole("Member");
    }
  };

  const handleRemoveItemClick = (type, identifier) => {
    setItemToDelete({ type, identifier });
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setItemToDelete({ type: null, identifier: null });
  };

  const handleDeleteUser = () => {
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      users: prevOrganization.users.filter((user) => user.username !== itemToDelete.identifier),
    }));
    handleDeleteDialogClose();
  };

  const handleDeleteGroup = () => {
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      userGroups: prevOrganization.userGroups.filter((group) => group.groupName !== itemToDelete.identifier),
    }));
    handleDeleteDialogClose();
  };

  const handleSave = async () => {
    try {
      await saveOrganization(organization);
      navigate("/organizations");
    } catch (error) {
      console.error("Error saving organization:", error);
    }
  };

  // Compute available users by excluding already added users
  const availableUsers = allUsers.filter((user) => !organization.users.some((orgUser) => orgUser.username === user.username));

  // Prepare users table data
  const usersTableData = organization.users.map((orgUser) => ({
    id: orgUser.username, // Use username as unique ID
    username: orgUser.username,
    role: orgUser.role,
  }));

  const usersTableConfig = {
    columns: [
      { label: "Username", key: "username", sortColumn: true },
      { label: "Role", key: "role", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => (
          <TableActionsColumn id={rowData.id} name={rowData.username} onDelete={() => handleRemoveItemClick("user", rowData.username)} />
        ),
      },
    ],
    sort: true,
    sticky: {
      maxHeight: "30vh",
      headerSticky: true,
    },
  };

  // Prepare groups table data
  const groupsTableData = organization.userGroups.map((orgGroup) => ({
    id: orgGroup.groupName, // Use groupName as unique ID
    groupName: orgGroup.groupName,
    role: orgGroup.role,
  }));

  const groupsTableConfig = {
    columns: [
      { label: "Group Name", key: "groupName", sortColumn: true },
      { label: "Role", key: "role", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => (
          <TableActionsColumn id={rowData.id} name={rowData.groupName} onDelete={() => handleRemoveItemClick("group", rowData.groupName)} />
        ),
      },
    ],
    sort: true,
    sticky: {
      maxHeight: "30vh",
      headerSticky: true,
    },
  };

  return (
    <div className="page-container">
      <PageTitle
        elements={[
          { name: "Organizations", route: "/organizations" },
          { name: organization.id ? organization.name : "New", route: "#" },
        ]}
      />
      <div className="form-group">
        <label htmlFor="organization-name">Name</label>
        <input id="organization-name" type="text" name="name" value={organization.name} onChange={handleInputChange} />
      </div>

      <div className="users-section">
        <div className="form-group">
          <label>Users</label>
          <div className="add-user-section">
            <select value={selectedUsername} onChange={(e) => setSelectedUsername(e.target.value)}>
              <option value="">Select a user to add</option>
              {availableUsers.map((user) => (
                <option key={user.username} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
            <select value={userRole} onChange={(e) => setUserRole(e.target.value)}>
              <option value="Member">Member</option>
              <option value="Owner">Owner</option>
            </select>
            <Button onPress={handleAddUser} disabled={!selectedUsername}>
              Add
            </Button>
          </div>
          <Table data={usersTableData} config={usersTableConfig} noDataMessage="No Users linked yet" className="table-compact" />
        </div>
      </div>

      <div className="groups-section">
        <div className="form-group">
          <label>User Groups</label>
          <div className="add-group-section">
            <input type="text" placeholder="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
            <select value={groupRole} onChange={(e) => setGroupRole(e.target.value)}>
              <option value="Member">Member</option>
              <option value="Owner">Owner</option>
            </select>
            <Button onPress={handleAddGroup} disabled={!groupName.trim()}>
              Add
            </Button>
          </div>
          <Table data={groupsTableData} config={groupsTableConfig} noDataMessage="No Groups linked yet" className="table-compact" />
        </div>
      </div>

      <div className="button-container">
        <Button onPress={handleSave}>Save</Button>
        <Button onPress={() => navigate("/organizations")}>Cancel</Button>
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={itemToDelete.type === "user" ? handleDeleteUser : handleDeleteGroup}
          message={`Are you sure you want to remove the ${itemToDelete.type === "user" ? "user" : "group"} '${
            itemToDelete.identifier
          }'? The removal will take effect once the Organization is saved.`}
          title={`Remove ${itemToDelete.type === "user" ? "User" : "Group"}?`}
        />
      )}
    </div>
  );
};

export default OrganizationFormPage;
