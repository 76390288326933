// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ./client/src/components/DataSources/DatasetGitHubPopup.css */  
  
.dataset-github-popup-content {  
  background: white;  
  padding: 20px;  
  border-radius: 10px;  
  width: 400px;  
  position: relative;  
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  
}  
  
.form-group {  
  margin-bottom: 15px;  
}  
  
.form-group label {  
  display: block;  
  margin-bottom: 5px;  
}  
  
.form-group input {  
  width: 100%;  
  padding: 8px;  
  border: 1px solid #ddd;  
  border-radius: 5px;  
  box-sizing: border-box;  
}  
  `, "",{"version":3,"sources":["webpack://./src/components/DataSources/DatasetGitHubPopup.css"],"names":[],"mappings":"AAAA,+DAA+D;;AAE/D;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":["/* ./client/src/components/DataSources/DatasetGitHubPopup.css */  \r\n  \r\n.dataset-github-popup-content {  \r\n  background: white;  \r\n  padding: 20px;  \r\n  border-radius: 10px;  \r\n  width: 400px;  \r\n  position: relative;  \r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  \r\n}  \r\n  \r\n.form-group {  \r\n  margin-bottom: 15px;  \r\n}  \r\n  \r\n.form-group label {  \r\n  display: block;  \r\n  margin-bottom: 5px;  \r\n}  \r\n  \r\n.form-group input {  \r\n  width: 100%;  \r\n  padding: 8px;  \r\n  border: 1px solid #ddd;  \r\n  border-radius: 5px;  \r\n  box-sizing: border-box;  \r\n}  \r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
