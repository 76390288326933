import { axiosInstance } from './config';  
  
export const fetchTeams = async (orgId) => {  
    try {  
        const response = await axiosInstance.get(`/api/team/all/${orgId}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching teams:', error);  
        throw error;  
    }  
};  
  
export const fetchTeam = async (id) => {  
    try {  
        const response = await axiosInstance.get(`/api/team/${id}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching team:', error);  
        throw error;  
    }  
};  
  
export const saveTeam = async (organizationId, team) => {  
    try {  
        if (team.id) {  
            await axiosInstance.put(`/api/team/${organizationId}`, team);  
        } else {  
            await axiosInstance.post(`/api/team/${organizationId}`, team);  
        }  
    } catch (error) {  
        console.error('Error saving team:', error);  
        throw error;  
    }  
};  
  
export const deleteTeam = async (organizationId, teamId) => {  
    try {  
        await axiosInstance.delete(`/api/team/${organizationId}/${teamId}`);  
    } catch (error) {  
        console.error('Error deleting team:', error);  
        throw error;  
    }  
};  
