import React, { useState, useEffect, useRef } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { fetchTeams, deleteTeam } from '../../services/team';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { FaEdit, FaTrash } from 'react-icons/fa';  
import './TeamsContentPage.css';  
import { Button } from "@uitk/react";  
import SplashPage from '../Splash/SplashPage';  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const TeamsContentPage = ({ selectedOrg }) => {  
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
    const [teamToDelete, setTeamToDelete] = useState(null);  

    if (selectedOrg === undefined) {  
        return (  
            <SplashPage message="This page will be available once an Team is selected." />
        );  
    }  

    const [teams, setTeams] = useState([]);  
    const navigate = useNavigate();  
  
    const fetchAllTeams = async () => {  
        try {  
            const teams = await fetchTeams(selectedOrg.id);  
            setTeams(teams);  
        } catch (error) {  
            console.error('Error fetching teams:', error);  
        }  
    };  
  
    useEffect(() => {  
        if (selectedOrg.id) {  
            fetchAllTeams();  
        }  
    }, [selectedOrg.id]);  
  
    const handleEditClick = (id) => {  
        navigate(`/teams/edit/${id}`);  
    };  
    
    const handleDeleteClick = (team) => {  
        setTeamToDelete(team);  
        setIsDeleteDialogOpen(true);  
    };  
    
    const handleDeleteDialogClose = () => {  
        setIsDeleteDialogOpen(false);  
    };  
    
    const handleDeleteDialogConfirm = async () => {
        await handleDelete(teamToDelete.id);  
        setIsDeleteDialogOpen(false);  
        setTeamToDelete(null);  
    };  

    const handleDelete = async (id) => {  
        await deleteTeam(selectedOrg.id, id);  
        setTeams(teams.filter(team => team.id !== id));  
    };  

    return (  
        <div className="page-container">  
            <div className="page-header"> 
                <PageTitle  
                    elements={[
                    { name: selectedOrg.name, route: "/" }, 
                    { name: "Teams", route: "/teams" }  ]}  
                />   
                <div className="page-title-description">Optionally defined collections of users for easier management of access to data sources.</div>
            </div>
            <div className="page-upper-body">  
                <Button onPress={() => navigate('/teams/new')}>Add new</Button>
            </div>  
            <div className="page-body">  
                <table>  
                    <thead>  
                        <tr>  
                            <th>Name</th>  
                            <th>Number of Users</th>  
                            <th  style={{ width: '90px' }}>Actions</th>  
                        </tr>  
                    </thead>  
                    <tbody>  
                        {teams.map((team) => (  
                            <tr key={team.id}>  
                                <td>{team.name}</td>  
                                <td>{team.users.length}</td>  
                                <td className="action-buttons-cell">  
                                    <button className="action-button" onClick={() => handleEditClick(team.id)}>  
                                        <FaEdit />  
                                    </button>  
                                    <button className="action-button" onClick={() => handleDeleteClick(team)}>  
                                        <FaTrash />  
                                    </button>  
                                </td>  
                            </tr>  
                        ))}  
                    </tbody>  
                </table>  
            </div>  
        {isDeleteDialogOpen && (  
            <ConfirmationDialog  
            open={isDeleteDialogOpen}  
            onClose={handleDeleteDialogClose}  
            onConfirm={handleDeleteDialogConfirm}  
            message={`Are you sure you want to delete Team '${teamToDelete.name}'? This action cannot be undone.`}
            title="Delete Team?"
            />  
        )}
        </div>  
    );  
};  
  
export default TeamsContentPage;  
