// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentation-container {  
    padding: 20px;  
    background-color: #ffffff;  
    border-radius: 10px;  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
  }  
    
  .search-bar {  
    margin-bottom: 20px;  
  }  
    
  .search-bar input {  
    width: 100%;  
    padding: 10px;  
    border: 1px solid #ddd;  
    border-radius: 5px;  
    box-sizing: border-box;  
  }  
    
  .documentation-section {  
    margin-bottom: 20px;  
  }  
    
  .documentation-section h3 {  
    margin-top: 0;  
    color: #002677;  
  }  
    
  .documentation-section p {  
    margin: 5px 0;  
  }  
    
  .documentation-section ul {  
    margin: 10px 0;  
    padding-left: 20px;  
  }  
    
  .documentation-section ul li {  
    margin-bottom: 5px;  
  }  
  `, "",{"version":3,"sources":["webpack://./src/pages/Resources/DocumentationPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".documentation-container {  \r\n    padding: 20px;  \r\n    background-color: #ffffff;  \r\n    border-radius: 10px;  \r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  \r\n  }  \r\n    \r\n  .search-bar {  \r\n    margin-bottom: 20px;  \r\n  }  \r\n    \r\n  .search-bar input {  \r\n    width: 100%;  \r\n    padding: 10px;  \r\n    border: 1px solid #ddd;  \r\n    border-radius: 5px;  \r\n    box-sizing: border-box;  \r\n  }  \r\n    \r\n  .documentation-section {  \r\n    margin-bottom: 20px;  \r\n  }  \r\n    \r\n  .documentation-section h3 {  \r\n    margin-top: 0;  \r\n    color: #002677;  \r\n  }  \r\n    \r\n  .documentation-section p {  \r\n    margin: 5px 0;  \r\n  }  \r\n    \r\n  .documentation-section ul {  \r\n    margin: 10px 0;  \r\n    padding-left: 20px;  \r\n  }  \r\n    \r\n  .documentation-section ul li {  \r\n    margin-bottom: 5px;  \r\n  }  \r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
