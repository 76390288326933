// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {  
  width: 100%;  
  border-collapse: collapse;  
  margin-top: 20px;  
  font-size: 16px;  
}  
  
th, td {  
  border-bottom: 1px solid #ddd;  
  padding: 12px 8px;  
  text-align: left;  
  font-weight: normal;  
}  
  
th {  
  background-color: #fafafa;  
  color: #333;  
}  
  
td {  
  background-color: #ffffff;  
  position: relative;  
}  
  `, "",{"version":3,"sources":["webpack://./src/pages/Users/UsersContentPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":["table {  \r\n  width: 100%;  \r\n  border-collapse: collapse;  \r\n  margin-top: 20px;  \r\n  font-size: 16px;  \r\n}  \r\n  \r\nth, td {  \r\n  border-bottom: 1px solid #ddd;  \r\n  padding: 12px 8px;  \r\n  text-align: left;  \r\n  font-weight: normal;  \r\n}  \r\n  \r\nth {  \r\n  background-color: #fafafa;  \r\n  color: #333;  \r\n}  \r\n  \r\ntd {  \r\n  background-color: #ffffff;  \r\n  position: relative;  \r\n}  \r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
