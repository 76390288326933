import React, { useState, useEffect } from 'react';  
import { useParams, useNavigate } from 'react-router-dom';  
import { fetchOrganization, saveOrganization } from '../../services/organization';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { Button } from "@uitk/react";  
import './OrganizationFormPage.css';  
import { fetchUser } from '../../services/user';  
import { FaTrash } from 'react-icons/fa';  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const OrganizationFormPage = ({ user, selectedOrg }) => {  
  const { id } = useParams();  
  const navigate = useNavigate();  
  const [organization, setOrganization] = useState({ name: '', users: [] });  
  const [username, setUsername] = useState('');  
  const [role, setRole] = useState('Member');  
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
  const [userToDelete, setUserToDelete] = useState(null);  
  
  useEffect(() => {  
    const fetchOrganizationData = async () => {  
      if (id) {  
        try {  
          const organizationData = await fetchOrganization(id);  
          setOrganization(organizationData);  
        } catch (error) {  
          console.error('Error fetching organization:', error);  
        }  
      }  
    };  
    fetchOrganizationData();  
  }, [id]);  
  
  const handleInputChange = (e) => {  
    const { name, value } = e.target;  
    setOrganization((prevOrganization) => ({ ...prevOrganization, [name]: value }));  
  };  
  
  const handleAddUser = async () => {  
    if (username) {  
      try {  
        const user = await fetchUser(username);  
        setOrganization((prevOrganization) => ({  
          ...prevOrganization,  
          users: [...prevOrganization.users, { username: user.username, role }],  
        }));  
        setUsername('');  
        setRole('Member');  
      } catch (error) {  
        alert('User not found');  
      }  
    }  
  };  
  
  const handleRemoveUserClick = (index) => {  
    setUserToDelete(index);  
    setIsDeleteDialogOpen(true);  
  };  
  
  const handleDeleteDialogClose = () => {  
    setIsDeleteDialogOpen(false);  
  };  
  
  const handleDeleteDialogConfirm = () => {  
    setOrganization((prevOrganization) => ({  
      ...prevOrganization,  
      users: prevOrganization.users.filter((_, i) => i !== userToDelete),  
    }));  
    setIsDeleteDialogOpen(false);  
    setUserToDelete(null);  
  };  
  
  const handleRoleChange = (index, newRole) => {  
    setOrganization((prevOrganization) => {  
      const updatedUsers = [...prevOrganization.users];  
      updatedUsers[index].role = newRole;  
      return { ...prevOrganization, users: updatedUsers };  
    });  
  };  
  
  const handleSave = async () => {  
    try {  
      await saveOrganization(organization);  
      navigate('/organizations');  
    } catch (error) {  
      console.error('Error saving organization:', error);  
    }  
  };  
  
  return (  
    <div className="form-container">  
      <PageTitle  
        elements={[  
          { name: "Organizations", route: "/organizations" },  
          { name: organization.id ? organization.name : 'New', route: "#" }  
        ]}  
      />  
      <div className="form-group">  
        <label>Name</label>  
        <input  
          type="text"  
          name="name"  
          value={organization.name}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="users-section">  
        <div className="form-group">  
          <label>Users</label>  
          <div className="add-user-section">  
            <input  
              type="text"  
              placeholder="Enter username"  
              value={username}  
              onChange={(e) => setUsername(e.target.value)}  
            />  
            <Button onPress={handleAddUser}>Add</Button>  
          </div>  
          <table className="table">  
            <tbody>  
              {organization.users.length === 0 ? (  
                <tr>  
                  <td colSpan="3">No Users linked yet</td>  
                </tr>  
              ) : (  
                organization.users.map((user, index) => (  
                  <tr key={index}>  
                    <td>{user.username}  
                      <div className="role-container">  
                        <select  
                          value={user.role}  
                          onChange={(e) => handleRoleChange(index, e.target.value)}  
                        >  
                          <option value="Member">Member</option>  
                          <option value="Owner">Owner</option>  
                        </select>  
                      </div>  
                      <span onClick={() => handleRemoveUserClick(index)}>  
                        <FaTrash className="action-button" />  
                      </span>  
                    </td>  
                  </tr>  
                ))  
              )}  
            </tbody>  
          </table>  
        </div>  
      </div>  
      <div className="button-container">  
        <Button onPress={handleSave}>Save</Button>  
        <Button onPress={() => navigate('/organizations')}>Cancel</Button>  
      </div>  
  
      {isDeleteDialogOpen && (  
        <ConfirmationDialog  
          open={isDeleteDialogOpen}  
          onClose={handleDeleteDialogClose}  
          onConfirm={handleDeleteDialogConfirm}  
          message={`Are you sure you want to remove the user '${organization.users[userToDelete]?.username}'? The removal will be performed once the Organization is saved. If you don't save the Organization, the user will not be removed.`}  
          title="Remove User?"  
        />  
      )}  
    </div>  
  );  
};  
  
export default OrganizationFormPage;  
