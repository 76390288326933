import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOrganizations, deleteOrganization } from "../../services/organization";
import { Button, Table } from "@uitk/react";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";
import TableActionsColumn from "../../components/Common/TableActionsColumn";

const OrganizationsContentPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const navigate = useNavigate();

  const fetchAllOrganizations = async () => {
    try {
      const organizations = await fetchOrganizations();
      setOrganizations(organizations);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/organizations/edit/${id}`);
  };

  const handleDeleteClick = (organization) => {
    setOrganizationToDelete(organization);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    await handleDelete(organizationToDelete.id);
    setIsDeleteDialogOpen(false);
    setOrganizationToDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      await deleteOrganization(id);
      setOrganizations(organizations.filter((org) => org.id !== id));
    } catch (error) {
      console.error("Error deleting organization:", error);
    }
  };

  // Prepare table data by mapping organizations to include the number of users
  const tableData = organizations.map((organization) => ({
    id: organization.id,
    name: organization.name,
    numberOfUsers: organization.users.length,
  }));

  // Define configuration for the uitk Table component
  const organizationsTableDescription = "Organizations are top-level entities that provide a separation layer for teams and data sources.";
  const tableConfig = {
    columns: [
      { label: "Name", key: "name", sortColumn: true },
      { label: "Number of Users", key: "numberOfUsers", sortColumn: true },
      {
        label: "Actions",
        onRenderCell: (rowData) => (
          <TableActionsColumn
            id={rowData.id}
            name={rowData.name}
            onEdit={() => handleEditClick(rowData.id)}
            onDelete={() =>
              handleDeleteClick({
                id: rowData.id,
                name: rowData.name,
              })
            }
          />
        ),
      },
    ],
    sort: true,
    caption: {
      headerText: "Organizations",
      descriptionText: organizationsTableDescription,
      // Override the caption description to prevent it from changing when user sorts a column
      getSortCaptionDescription: () => organizationsTableDescription,
    },
    sticky: {
      maxHeight: "70vh",
      headerSticky: true,
    },
  };

  return (
    <div className="page-container">
      <div className="page-upper-body">
        <Button onPress={() => navigate("/organizations/new")}>Add new</Button>
      </div>
      <div className="page-body">
        <Table data={tableData} config={tableConfig} noDataMessage="No organizations available." className="table-compact" />
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to delete Organization '${organizationToDelete.name}'? This action cannot be undone.`}
          title="Delete Organization?"
        />
      )}
    </div>
  );
};

export default OrganizationsContentPage;
