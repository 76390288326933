import React from "react";
import ReactDOM from "react-dom";
import { Notification } from "@uitk/react";
import "./ErrorNotification.css";

/**
 * ErrorNotification component for rendering error messages using React Portals.
 *
 * This component utilizes React Portals to render error notifications outside the main
 * React component hierarchy. It is designed to display error messages in a styled popup
 * and allows users to dismiss the notification via a close button.
 *
 * The notification is mounted to a DOM element with the ID `modal-root`.
 *
 * @component
 * @example
 * ```jsx
 * <ErrorNotification
 *   error="An unexpected error occurred."
 *   onDismiss={() => console.log('Notification dismissed')}
 * />
 * ```
 *
 * @param {Object} props - Component props.
 * @param {string} props.error - The error message to display. If falsy, the component renders nothing.
 * @param {() => void} props.onDismiss - Callback function triggered when the notification is dismissed.
 * @returns {ReactPortal|null} A React Portal containing the error notification, or `null` if no error is provided.
 */
const ErrorNotification = ({ error, onDismiss }) => {
  if (!error) return null;

  return ReactDOM.createPortal(
    <div className="overlay">
      <div className="notification-popup">
        <Notification onDismiss={onDismiss} buttonText={"Close"} variant={"error"} id={"notification-error"} show={!!error} className="mv-m">
          {error}
        </Notification>
      </div>
    </div>,
    document.getElementById("modal-root") // Mount the popup in modal-root
  );
};

export default ErrorNotification;
