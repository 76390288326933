// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
    
  .iframe-container {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Chat/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,YAAY;EACd","sourcesContent":[".modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .modal-content {\r\n    background: white;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    width: 80%;\r\n    height: 80%;\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n    \r\n  .iframe-container {\r\n    flex-grow: 1;\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
