// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {  
  margin-bottom: 15px;  
}  
  
.form-group label {  
  display: block;  
  margin-bottom: 5px;  
}  
  
.form-group input, .form-group select {  
  width: 100%;  
  padding: 8px;  
  box-sizing: border-box;  
}  
  
.add-user-section {  
  display: flex;  
  justify-content: flex-start;  
  margin-top: 10px;  
}  
  
.add-user-section input {  
  width: auto;  
  padding: 8px;  
  box-sizing: border-box;  
  margin-right: 10px;  
}  
  
.add-user-section button {  
  width: auto;  
  padding: 8px;  
  background-color: #002677;  
  color: white;  
  border: none;  
  border-radius: 5px;  
  cursor: pointer;  
}  
      
.users-section {  
  margin-top: 30px;  
  padding: 15px;  
  border: 1px solid #ddd;  
  border-radius: 5px;  
  background-color: #f9f9f9;  
}  `, "",{"version":3,"sources":["webpack://./src/pages/Teams/TeamFormPage.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".form-group {  \r\n  margin-bottom: 15px;  \r\n}  \r\n  \r\n.form-group label {  \r\n  display: block;  \r\n  margin-bottom: 5px;  \r\n}  \r\n  \r\n.form-group input, .form-group select {  \r\n  width: 100%;  \r\n  padding: 8px;  \r\n  box-sizing: border-box;  \r\n}  \r\n  \r\n.add-user-section {  \r\n  display: flex;  \r\n  justify-content: flex-start;  \r\n  margin-top: 10px;  \r\n}  \r\n  \r\n.add-user-section input {  \r\n  width: auto;  \r\n  padding: 8px;  \r\n  box-sizing: border-box;  \r\n  margin-right: 10px;  \r\n}  \r\n  \r\n.add-user-section button {  \r\n  width: auto;  \r\n  padding: 8px;  \r\n  background-color: #002677;  \r\n  color: white;  \r\n  border: none;  \r\n  border-radius: 5px;  \r\n  cursor: pointer;  \r\n}  \r\n      \r\n.users-section {  \r\n  margin-top: 30px;  \r\n  padding: 15px;  \r\n  border: 1px solid #ddd;  \r\n  border-radius: 5px;  \r\n  background-color: #f9f9f9;  \r\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
