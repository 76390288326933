// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasource-filelist-popup-content {  
    width: 80%;  
    height: 50%;  
    max-height: 80%;  
    background: white;  
    padding: 20px;  
    border-radius: 10px;  
    position: relative;  
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  
}  
  
.file-list {  
    width: 100%;  
    height: 100%;  
    overflow-y: auto;  
}  
  
.file-table {  
    width: 100%;  
    border-collapse: collapse;  
    margin-top: 10px;  
}  
  
.file-table th, .file-table td {  
    border: 1px solid #ddd;  
    padding: 8px;  
    text-align: left;  
}  
  
.file-table th {  
    background-color: #fafafa;  
    color: #333;  
}  
  
.file-table td {  
    background-color: #ffffff;  
}  
  
.file-list-icon-spin {  
    animation: spin 1s linear infinite;  
    font-size: 48px;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    height: 50px;  
    width: 50px;  
    position: absolute;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%);  
}  
  
@keyframes spin {  
    0% { transform: rotate(0deg); }  
    100% { transform: rotate(360deg); }  
}  
`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DataSourceFileListPopup.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".datasource-filelist-popup-content {  \r\n    width: 80%;  \r\n    height: 50%;  \r\n    max-height: 80%;  \r\n    background: white;  \r\n    padding: 20px;  \r\n    border-radius: 10px;  \r\n    position: relative;  \r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  \r\n}  \r\n  \r\n.file-list {  \r\n    width: 100%;  \r\n    height: 100%;  \r\n    overflow-y: auto;  \r\n}  \r\n  \r\n.file-table {  \r\n    width: 100%;  \r\n    border-collapse: collapse;  \r\n    margin-top: 10px;  \r\n}  \r\n  \r\n.file-table th, .file-table td {  \r\n    border: 1px solid #ddd;  \r\n    padding: 8px;  \r\n    text-align: left;  \r\n}  \r\n  \r\n.file-table th {  \r\n    background-color: #fafafa;  \r\n    color: #333;  \r\n}  \r\n  \r\n.file-table td {  \r\n    background-color: #ffffff;  \r\n}  \r\n  \r\n.file-list-icon-spin {  \r\n    animation: spin 1s linear infinite;  \r\n    font-size: 48px;  \r\n    display: flex;  \r\n    justify-content: center;  \r\n    align-items: center;  \r\n    height: 50px;  \r\n    width: 50px;  \r\n    position: absolute;  \r\n    top: 50%;  \r\n    left: 50%;  \r\n    transform: translate(-50%, -50%);  \r\n}  \r\n  \r\n@keyframes spin {  \r\n    0% { transform: rotate(0deg); }  \r\n    100% { transform: rotate(360deg); }  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
