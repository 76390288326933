import React, { useState, useEffect } from 'react';  
import { Link } from 'react-router-dom';  
import { FaComments, FaDatabase, FaUser, FaUsers, FaChartLine, FaBook, FaLifeRing, FaBuilding } from 'react-icons/fa';  
import './Sidebar.css';  
  
const Sidebar = ({ activeMenu, setActiveMenu, user }) => {    
    return (  
        <div className="sidebar">  
            <div className="menu-item">  
                <div className="menu-label parent-menu-label">Assistant</div>  
                <div className="submenu">  
                    <Link  
                        to="/chat"  
                        className={activeMenu === 'Chat' ? 'active' : ''}  
                        onClick={() => setActiveMenu('Chat')}  
                        style={{ paddingLeft: '20px' }}  
                    >  
                        <FaComments />  
                        <span>Chat</span>  
                    </Link>  
                </div>  
            </div>  
            {user.isSystemAdmin && (  
                <>  
                    <div className="menu-item">  
                        <div className="menu-label parent-menu-label">System</div>  
                            <div className="submenu">  
                            <Link  
                                to="/users"  
                                className={activeMenu === 'Users' ? 'active' : ''}  
                                onClick={() => setActiveMenu('Users')}  
                                style={{ paddingLeft: '20px' }}  
                            >  
                                <FaUser />  
                                <span>Users</span>  
                            </Link>  
                            <Link  
                                to="/organizations"  
                                className={activeMenu === 'Organizations' ? 'active' : ''}  
                                onClick={() => setActiveMenu('Organizations')}  
                                style={{ paddingLeft: '20px' }}  
                            >  
                                <FaBuilding />  
                                <span>Organizations</span>  
                            </Link>  
                                
                            </div>  
                    </div>  
                </>  
            )}
            {(user.isSystemAdmin || user.isOrganizationOwner || user.canViewDataSourcesPage) && (  
                <div className="menu-item">  
                    <div className="menu-label parent-menu-label">Organization</div>  
                        <div className="submenu">  
                            {(user.isSystemAdmin || user.isOrganizationOwner) && (  
                                <Link  
                                    to="/teams"  
                                    className={activeMenu === 'Teams' ? 'active' : ''}  
                                    onClick={() => setActiveMenu('Teams')}  
                                    style={{ paddingLeft: '20px' }}  
                                >  
                                    <FaUsers />  
                                    <span>Teams</span>  
                                </Link>  
                            )}  
                            <Link  
                                to="/datasources"  
                                className={activeMenu === 'DataSources' ? 'active' : ''}  
                                onClick={() => setActiveMenu('DataSources')}  
                                style={{ paddingLeft: '20px' }}  
                            >  
                                <FaDatabase />  
                                <span>Data Sources</span>  
                            </Link>  
                            {(user.isSystemAdmin || user.isOrganizationOwner) && (  
                                <Link  
                                    to="/insights"  
                                    className={activeMenu === 'Insights' ? 'active' : ''}  
                                    onClick={() => setActiveMenu('Insights')}  
                                    style={{ paddingLeft: '20px' }}  
                                >  
                                    <FaChartLine />  
                                    <span>Insights</span>  
                                </Link>  
                            )}  
                        </div>  
                </div>  
            )}  
            <div className="menu-item">  
                <div className="menu-label parent-menu-label">Resources</div>  
                <div className="submenu">  
                    <Link  
                        to="/guide"  
                        className={activeMenu === 'Help' ? 'active' : ''}  
                        onClick={() => setActiveMenu('Help')}  
                        style={{ paddingLeft: '20px' }}  
                    >  
                        <FaBook />  
                        <span>Guide</span>  
                    </Link>  
                    <Link  
                        to="/support"  
                        className={activeMenu === 'Support' ? 'active' : ''}  
                        onClick={() => setActiveMenu('Support')}  
                        style={{ paddingLeft: '20px' }}  
                    >  
                        <FaLifeRing />  
                        <span>Support</span>  
                    </Link>  
                </div>  
            </div>  
        </div>  
    );  
};  
  
export default Sidebar;  
