// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProfilePopup.css */  
.profile-popup-content {  
  background: white;  
  padding: 20px;  
  border-radius: 10px;  
  width: 80%;  
  max-width: 1200px;  
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  
  position: relative;  
}  
  
.profile-container {  
  padding: 0;  
  margin-top: 20px;  
}  
  
.profile-field {  
  margin-bottom: 15px;  
}  
  
.profile-field label {  
  display: block;  
  margin-bottom: 5px;  
  font-weight: bold;  
}  
  
.profile-field input {  
  width: 100%;  
  padding: 10px;  
  border: 1px solid #ddd;  
  border-radius: 5px;  
  background-color: #f9f9f9;  
  color: #333;  
  box-sizing: border-box;  
}  
  
.permissions-section {  
  margin-top: 20px;  
}  
  
.permissions-section h3 {  
  margin-bottom: 10px;  
}  
  
.permissions-table {  
  width: 100%;  
  border-collapse: collapse;  
  margin-bottom: 20px;  
}  
  
.permissions-table th,  
.permissions-table td {  
  border: 1px solid #ddd;  
  padding: 8px;  
  text-align: left;  
}  
  
.permissions-table th {  
  background-color: #fafafa;  
  color: #333;  
}  
  
.permissions-table td {  
  background-color: #ffffff;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/Profile/ProfilePopup.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* ProfilePopup.css */  \r\n.profile-popup-content {  \r\n  background: white;  \r\n  padding: 20px;  \r\n  border-radius: 10px;  \r\n  width: 80%;  \r\n  max-width: 1200px;  \r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  \r\n  position: relative;  \r\n}  \r\n  \r\n.profile-container {  \r\n  padding: 0;  \r\n  margin-top: 20px;  \r\n}  \r\n  \r\n.profile-field {  \r\n  margin-bottom: 15px;  \r\n}  \r\n  \r\n.profile-field label {  \r\n  display: block;  \r\n  margin-bottom: 5px;  \r\n  font-weight: bold;  \r\n}  \r\n  \r\n.profile-field input {  \r\n  width: 100%;  \r\n  padding: 10px;  \r\n  border: 1px solid #ddd;  \r\n  border-radius: 5px;  \r\n  background-color: #f9f9f9;  \r\n  color: #333;  \r\n  box-sizing: border-box;  \r\n}  \r\n  \r\n.permissions-section {  \r\n  margin-top: 20px;  \r\n}  \r\n  \r\n.permissions-section h3 {  \r\n  margin-bottom: 10px;  \r\n}  \r\n  \r\n.permissions-table {  \r\n  width: 100%;  \r\n  border-collapse: collapse;  \r\n  margin-bottom: 20px;  \r\n}  \r\n  \r\n.permissions-table th,  \r\n.permissions-table td {  \r\n  border: 1px solid #ddd;  \r\n  padding: 8px;  \r\n  text-align: left;  \r\n}  \r\n  \r\n.permissions-table th {  \r\n  background-color: #fafafa;  \r\n  color: #333;  \r\n}  \r\n  \r\n.permissions-table td {  \r\n  background-color: #ffffff;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
