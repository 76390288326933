// File: src\components\DataSources\DataSourceAccessControlTab.js
import React, { useState, useEffect } from "react";
import { getStoredOrg, fetchOrganization } from "../../services/organization";
import "./DataSourceAccessControlTab.css";
import { FaTrash } from "react-icons/fa";
import { Button, Dropdown, Tooltip } from "@uitk/react";
import { InfoIcon } from "@uitk/react-icons";
import styled, { css } from "styled-components";
import ConfirmationDialog from "../../components/Common/ConfirmationDialog";

const DataSourceAccessControlTab = ({ currentUser, availableUsers, availableTeams, dataSource, setDataSource }) => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);

  const accessMode = dataSource.accessMode || "Restricted";
  const isRestricted = accessMode === "Restricted";

  useEffect(() => {
    const filterTeams = () => {
      const filtered = availableTeams.filter((team) => !dataSource.teams.some((t) => t.id === team.id));
      setFilteredTeams(filtered);
    };
    filterTeams();
  }, [dataSource.teams, availableTeams]);

  const handleAddTeam = () => {
    if (selectedTeam) {
      const team = availableTeams.find((team) => team.id === parseInt(selectedTeam.value));
      const updatedTeams = [...dataSource.teams, { ...team, permission: "Read" }];
      setDataSource({ ...dataSource, teams: updatedTeams });
      setSelectedTeam(null);
    }
  };

  const handleAddUser = () => {
    if (selectedUser) {
      const user = availableUsers.find((user) => user.username.toLowerCase() === selectedUser.value.toLowerCase());
      const updatedUsers = [...dataSource.users, { ...user, permission: "Read" }];
      setDataSource({ ...dataSource, users: updatedUsers });
      setSelectedUser(null);
    }
  };

  const handlePermissionChange = (index, type, value) => {
    if (type === "User") {
      const updatedUsers = [...dataSource.users];
      updatedUsers[index].permission = value;
      setDataSource({ ...dataSource, users: updatedUsers });
    } else {
      const updatedTeams = [...dataSource.teams];
      updatedTeams[index].permission = value;
      setDataSource({ ...dataSource, teams: updatedTeams });
    }
  };

  const StyledInfoFilled = styled(InfoIcon)`
    ${({ theme }) => css`
      fill: ${theme.UitkTooltipColorIndicator};
    `}
  `;

  const handleRemoveUserClick = (index, username) => {
    setEntityToDelete({ type: "User", entityName: username, index });
    setIsDeleteDialogOpen(true);
  };

  const handleRemoveTeamClick = (index, name) => {
    setEntityToDelete({ type: "Team", entityName: name, index });
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    if (entityToDelete.type === "User") {
      const updatedUsers = dataSource.users.filter((_, i) => i !== entityToDelete.index);
      setDataSource({ ...dataSource, users: updatedUsers });
    } else if (entityToDelete.type === "Team") {
      const updatedTeams = dataSource.teams.filter((_, i) => i !== entityToDelete.index);
      setDataSource({ ...dataSource, teams: updatedTeams });
    }
    setIsDeleteDialogOpen(false);
    setEntityToDelete(null);
  };

  const handleAccessModeChange = (event) => {
    const mode = event.target.value;
    setDataSource({ ...dataSource, accessMode: mode });
  };

  const filteredUsers = availableUsers.filter((orgUser) => !dataSource.users.some((user) => user.username.toLowerCase() === orgUser.username.toLowerCase()));
  const teamItems = filteredTeams.map((team) => ({
    id: team.id.toString(),
    label: team.name,
    value: team.id.toString(),
  }));
  const userItems = filteredUsers.map((user) => ({
    id: user.username,
    label: `${user.firstName} ${user.lastName} (${user.username})`,
    value: user.username,
  }));

  return (
    <div className="access-control-section">
      <div className="access-control-subsection">
        <div className="title-with-tooltip">
          <h3>Access Mode</h3>
          <Tooltip
            placement="right-start"
            content={
              <>
                <b>Public Within the Organization</b>: Everyone within the Organization will have automatic read-only access to the Data Source, but only Organization Owners
                will be able to make changes to it.
                <br />
                <br />
                <b>Restricted</b>: By default, the Data Source will be restricted to Organization Owners only. Additional Users and Teams can be added to the
                Data Source on demand.
              </>
            }
          >
            <StyledInfoFilled size="16" />
          </Tooltip>
        </div>

        <div className="access-mode-selector">
          <label className="radio-label">
            <input type="radio" name="accessMode" value="PublicWithinOrganization" checked={accessMode === "PublicWithinOrganization"} onChange={handleAccessModeChange} />
            Public Within the Organization
          </label>
          <label className="radio-label">
            <input type="radio" name="accessMode" value="Restricted" checked={accessMode === "Restricted"} onChange={handleAccessModeChange} />
            Restricted
          </label>
        </div>
      </div>

      <div className="access-control-subsection" aria-disabled={!isRestricted}>
        <div className="title-with-tooltip">
          <h3>Users</h3>
          <Tooltip
            placement="right-start"
            content="Users can be granted access to this Data Source. Users must first be added to the Organization by a System Admin."
          >
            <StyledInfoFilled size="16" />
          </Tooltip>
        </div>
        <div className="dropdown-add-container">
          <Dropdown type="single" items={userItems} value={selectedUser} onChange={setSelectedUser} disabled={!isRestricted} />
          <Button onPress={handleAddUser} disabled={!isRestricted}>
            Add
          </Button>
        </div>
        <table className="access-control-table">
          <tbody>
            {dataSource.users.map((user, index) => (
              <tr key={index}>
                <td>
                  {`${user.firstName} ${user.lastName} (${user.username})`}
                  <div className="permission-container">
                    <select value={user.permission} onChange={(e) => handlePermissionChange(index, "User", e.target.value)} disabled={!isRestricted}>
                      <option value="Read">Read</option>
                      <option value="Write">Write</option>
                    </select>
                    <span
                      onClick={() => isRestricted && handleRemoveUserClick(index, user.username)}
                      style={{
                        cursor: isRestricted ? "pointer" : "not-allowed",
                      }}
                    >
                      <FaTrash className="action-button" />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="access-control-subsection" aria-disabled={!isRestricted}>
        <div className="title-with-tooltip">
          <h3>Teams</h3>
          <Tooltip
            placement="right-start"
            content="Multiple Organization Users can be granted access to this Data Source by adding them to a Team and granting the Team access to this Data Source."
          >
            <StyledInfoFilled size="16" />
          </Tooltip>
        </div>
        <div className="dropdown-add-container">
          <Dropdown type="single" items={teamItems} value={selectedTeam} onChange={setSelectedTeam} disabled={!isRestricted} />
          <Button onPress={handleAddTeam} disabled={!isRestricted}>
            Add
          </Button>
        </div>
        <table className="table">
          <tbody>
            {dataSource.teams.length === 0 ? (
              <tr>
                <td>No teams linked yet</td>
              </tr>
            ) : (
              dataSource.teams.map((team, index) => (
                <tr key={index}>
                  <td>
                    {team.name}
                    <div className="permission-container">
                      <select value={team.permission} onChange={(e) => handlePermissionChange(index, "Team", e.target.value)} disabled={!isRestricted}>
                        <option value="Read">Read</option>
                        <option value="Write">Write</option>
                      </select>
                      <span
                        onClick={() => isRestricted && handleRemoveTeamClick(index, team.name)}
                        style={{
                          cursor: isRestricted ? "pointer" : "not-allowed",
                        }}
                      >
                        <FaTrash className="action-button" />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
          message={`Are you sure you want to remove ${entityToDelete?.type} '${entityToDelete?.entityName}'? The removal will take effect once the Data Source is saved.`}
          title={`Remove ${entityToDelete?.type}?`}
        />
      )}
    </div>
  );
};

export default DataSourceAccessControlTab;
