import React from 'react';  
import './SplashPage.css';  
  
const SplashPage = ({ message }) => {  
  return (  
    <div className="no-org-container">  
      <h2>{message}</h2>  
    </div>  
  );  
};  
  
export default SplashPage;  
