import React, { useState, useEffect } from 'react';  
import ChatWindow from '../../components/Chat/ChatWindow';  
import { FaTrash } from 'react-icons/fa';  
import { submitChatCompletion } from '../../services/completion';  
import './ChatContentPage.css';  
  
const ChatContentPage = ({ selectedOrg, user }) => {  
  const [messages, setMessages] = useState([]);  
  const [prompt, setPrompt] = useState('');  
  const [selectedDataSource, setSelectedDataSource] = useState(null);  
  const [isTyping, setIsTyping] = useState(false);  
  const [localDataSources, setLocalDataSources] = useState([]);  
  
  const chatHistoryKey = `chatHistory_${selectedOrg?.id}`;  
  
  useEffect(() => {  
    const fetchData = async () => {  
      if (selectedOrg && selectedOrg.id) {  
        const organization = user.organizations.find(org => org.id === selectedOrg.id);  
        if (organization) {  
          setLocalDataSources(organization.dataSources);  
  
          const initialSource = organization.dataSources[0];  
          let displayMessages = JSON.parse(localStorage.getItem(chatHistoryKey)) || [];  
          displayMessages = displayMessages.filter(message => message.role !== 'system' && !message.isTyping);  
          if (initialSource) {  
            displayMessages.push({ role: 'system', content: `Using data source ${initialSource.dataSourceName}`, dataSourceId: initialSource.dataSourceId });  
          }  
          setMessages(displayMessages);  
          setSelectedDataSource(initialSource);  
          setPrompt('');            
        }  
      }  
    };  
    fetchData();  
  }, [selectedOrg, user.organizations]);  
  
  useEffect(() => {  
    if (selectedOrg && selectedOrg.id) {  
      localStorage.setItem(chatHistoryKey, JSON.stringify(messages));  
    }  
  }, [messages]);  
  
  const handleSubmit = async () => {  
    if (!prompt) {  
      return;  
    }  
  
    const newUserMessage = { role: 'user', content: prompt, dataSourceId: selectedDataSource?.dataSourceId };  
    const newAssistantTypingMessage = { role: 'assistant', content: '...', isTyping: true, dataSourceId: selectedDataSource?.dataSourceId };  
    let newMessages = [...messages, newUserMessage, newAssistantTypingMessage];  
    setMessages(newMessages);  
    setPrompt('');  
  
    setIsTyping(true);  
  
    let newAssistantMessage = {};  
    try {  
      const filteredMessages = newMessages.filter(msg => !msg.isTyping && !msg.isError && (msg.role === 'assistant' || msg.role === 'user'));  
      const result = await submitChatCompletion(prompt, filteredMessages, selectedDataSource?.dataSourceId, selectedOrg?.id);  
      newAssistantMessage = { role: 'assistant', content: result.completion, sources: result.sources, dataSourceId: result?.dataSourceId };  
    } catch (error) {  
      console.error('Error:', error);  
      newAssistantMessage = { role: 'assistant', content: 'error', isError: true };  
    } finally {  
      setIsTyping(false);  
      newMessages = [...newMessages.filter(msg => !msg.isTyping), newAssistantMessage];  
      setMessages(newMessages);  
    }  
  };  
  
  const handleReSubmit = async () => {    
    const filteredCurrentMessages = messages.filter(msg => !msg.isTyping && !msg.isError && (msg.role === 'assistant' || msg.role === 'user'));  
    const newAssistantTypingMessage = { role: 'assistant', content: '...', isTyping: true, dataSourceId: selectedDataSource?.dataSourceId };  
    let newMessages = [...filteredCurrentMessages, newAssistantTypingMessage];  
    setMessages(newMessages);  
    setIsTyping(true);
    let newAssistantMessage = {};  
    try {  
      const lastPrompt = filteredCurrentMessages[filteredCurrentMessages.length - 1].content;
      const result = await submitChatCompletion(lastPrompt, filteredCurrentMessages, selectedDataSource?.dataSourceId, selectedOrg?.id);  
      newAssistantMessage = { role: 'assistant', content: result.completion, sources: result.sources, dataSourceId: result?.dataSourceId };  
    } catch (error) {  
      console.error('Error:', error);  
      newAssistantMessage = { role: 'assistant', content: 'error', isError: true };  
    } finally {  
      setIsTyping(false);  
      newMessages = [...newMessages.filter(msg => !msg.isTyping), newAssistantMessage];  
      setMessages(newMessages);  
    }  
  };  

  const handlePromptChange = (event) => {  
    if (!event || !event.target) {  
      console.error('Invalid event object:', event);  
      return;  
    }  
    setPrompt(event.target.value);  
  };  
  
  const handleSourceChange = (event) => {  
    const selectedSource = localDataSources.find(source => source.dataSourceId === parseInt(event.target.value));  
    if (selectedSource) {  
      const sourceChangeMessage = { role: 'system', content: `Now using data source ${selectedSource.dataSourceName}`, dataSourceId: selectedSource.dataSourceId };  
      setMessages([...messages, sourceChangeMessage]);  
      setSelectedDataSource(selectedSource);  
    }  
  };  
  
  const handleClearMessages = () => {  
    if (selectedOrg && selectedOrg.id) {  
      localStorage.removeItem(chatHistoryKey);  
      const selectedSource = localDataSources.find(source => source.dataSourceId === parseInt(selectedDataSource?.dataSourceId));  
      if (selectedSource) {  
        const initialMessage = { role: 'system', content: `Using data source ${selectedSource.dataSourceName}`, dataSourceId: selectedSource.dataSourceId };  
        setMessages([initialMessage]);  
      }  
      else {
        setMessages([]);
      }
    }  
  };  
  
  return (  
    <div className="page-container">  
      <div className="chat-top-panel">  
        <div className="dropdown-container">  
          <div className="data-source-select stylish-select">  
            <label>Data Source: </label>  
            <select  
              value={selectedDataSource ? selectedDataSource.dataSourceId : ''}  
              onChange={handleSourceChange}  
            >  
              {localDataSources.length === 0 ? (  
                <option value="">None</option>  
              ) : (  
                localDataSources.map((source) => (  
                  <option key={source.dataSourceId} value={source.dataSourceId}>  
                    {source.dataSourceName}  
                  </option>  
                ))  
              )}  
            </select>  
          </div>  
        </div>  
        <button className="clear-button" onClick={handleClearMessages}>  
          <FaTrash /> Clear Chat  
        </button>  
      </div>  
      <ChatWindow  
        key={messages.length}  
        messages={messages}  
        handleSubmit={handleSubmit}  
        handleReSubmit={handleReSubmit}
        prompt={prompt}  
        setPrompt={handlePromptChange}  
        isTyping={isTyping}  
      />  
    </div>  
  );  
};  
  
export default ChatContentPage;  
