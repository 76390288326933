import React, { useState, useEffect } from 'react';  
import { useParams, useNavigate } from 'react-router-dom';  
import { saveTeam, fetchTeam } from '../../services/team';  
import { fetchOrganization } from '../../services/organization';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { Button, Dropdown, Tooltip } from "@uitk/react";  
import './TeamFormPage.css';  
import { FaTrash } from 'react-icons/fa';  
import { InfoIcon } from "@uitk/react-icons"; 
import styled, { css } from "styled-components";
  
const TeamFormPage = ({ user, selectedOrg }) => {  
  const { id } = useParams();  
  const navigate = useNavigate();  
  const [team, setTeam] = useState({ name: '', users: [] });  
  const [organizationUsers, setOrganizationUsers] = useState([]);  
  const [selectedUser, setSelectedUser] = useState(null);  
  
  useEffect(() => {  
    const fetchTeamData = async () => {  
      if (id) {  
        try {  
          const teamData = await fetchTeam(id);  
          setTeam(teamData);  
        } catch (error) {  
          console.error('Error fetching team:', error);  
        }  
      }  
    };  
    fetchTeamData();  
  }, [id, selectedOrg.id]);  
  
  useEffect(() => {  
    const fetchOrgUsers = async () => {  
      try {  
        const org = await fetchOrganization(selectedOrg.id);  
        setOrganizationUsers(org.users);  
      } catch (error) {  
        console.error('Error fetching organization users:', error);  
      }  
    };  
    fetchOrgUsers();  
  }, [selectedOrg.id]);  
  
  const handleInputChange = (e) => {  
    const { name, value } = e.target;  
    setTeam((prevTeam) => ({ ...prevTeam, [name]: value }));  
  };  
  
  const handleAddUser = () => {  
    if (selectedUser) {  
      const user = organizationUsers.find(user => user.username.toLowerCase() === selectedUser.value.toLowerCase());  
      setTeam((prevTeam) => ({  
        ...prevTeam,  
        users: [...prevTeam.users, user],  
      }));  
      setSelectedUser(null);  
    }  
  };  
  
  const handleRemoveUser = (index) => {  
    const updatedUsers = team.users.filter((_, i) => i !== index);  
    setTeam((prevTeam) => ({ ...prevTeam, users: updatedUsers }));  
  };  
  
  const handleSave = async () => {  
    const teamWithUsernames = {  
      ...team,  
      usernames: team.users.map(user => user.username),  
      organizationId: selectedOrg.id  
    };  
    try {  
      await saveTeam(selectedOrg.id, teamWithUsernames);  
      navigate('/teams');  
    } catch (error) {  
      console.error('Error saving team:', error);  
    }  
  };  
  
  const userItems = organizationUsers.filter(orgUser => !team.users.some(user => user.username.toLowerCase() === orgUser.username.toLowerCase())).map(user => ({ id: user.username, label: `${user.firstName} ${user.lastName} (${user.username})`, value: user.username }));  
  
  const StyledInfoFilled = styled(InfoIcon)`
  ${({ theme }) => css`
    fill: ${theme.UitkTooltipColorIndicator};
  `}
  `;

  return (  
    <div className="page-container">  
      <PageTitle  
        elements={[  
          { name: selectedOrg.name, route: "/" },  
          { name: "Teams", route: "/teams" },  
          { name: team.id ? team.name : 'New', route: "#" }  
        ]}  
      />  
      <div className="form-group">  
        <label>Name</label>  
        <input  
          type="text"  
          name="name"  
          value={team.name}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="users-section">  
        <div className="form-group">  
          <div className="title-with-tooltip">
            <h3>Users</h3>  
            <Tooltip placement="right-start" content="Users must first be added to the Organization by a System Admin before they can be added to a team.">
              <StyledInfoFilled size="16" />
            </Tooltip>
          </div> 
          <div className="dropdown-add-container">  
            <Dropdown  
              type="single"  
              items={userItems}  
              value={selectedUser}  
              onChange={setSelectedUser}  
            />  
            <Button onPress={handleAddUser}>Add</Button>  
          </div>  
          <table className="table">  
            <tbody>  
              {team.users.length === 0 ? (  
                <tr>  
                  <td>No Users linked yet</td>  
                </tr>  
              ) : (  
                team.users.map((user, index) => (  
                  <tr key={index}>  
                    <td>  
                      {`${user.firstName} ${user.lastName} (${user.username})`}  
                      <span onClick={() => handleRemoveUser(index)}>  
                        <FaTrash className="action-button" />  
                      </span>  
                    </td>  
                  </tr>  
                ))  
              )}  
            </tbody>  
          </table>  
        </div>  
      </div>  
      <div className="button-container">  
        <Button onPress={handleSave}>Save</Button>  
        <Button onPress={() => navigate('/teams')}>Cancel</Button>  
      </div>  
    </div>  
  );  
};  
  
export default TeamFormPage;  
