import React, { useEffect, useState } from 'react';  
import './DataSourceDetailsTab.css';  
import { Tooltip } from "@uitk/react";  
import { InfoIcon } from "@uitk/react-icons";  
import styled, { css } from "styled-components";  
  
const DataSourceDetailsTab = ({ newSource, handleInputChange }) => {  
  const [isDefaultType, setIsDefaultType] = useState(newSource.type === 'SystemDefault');  
  
  const handleTypeChange = (e) => {  
    const { value } = e.target;  
    handleInputChange(e);  
    setIsDefaultType(value === 'SystemDefault');  
  };  
  
  useEffect(() => {  
    if (newSource.type === 'SystemDefault') {  
      setIsDefaultType(true);  
    } else {  
      setIsDefaultType(false);  
    }  
  }, [newSource.type, handleInputChange]);  
  
  const handleNestedInputChange = (e) => {  
    const { name, value } = e.target;  
    const [parentKey, childKey] = name.split('.');  
    handleInputChange({  
      target: {  
        name: parentKey,  
        value: {  
          ...newSource[parentKey],  
          [childKey]: childKey === 'maxResults' ? Number(value) : value,  
        },  
      },  
    });  
  };  
  
  const StyledInfoFilled = styled(InfoIcon)`  
  ${({ theme }) => css`  
      fill: ${theme.UitkTooltipColorIndicator};  
  `}  
`;  

  return (  
    <div className="form-section">  
      <div className="form-group">  
        <label>Name</label>  
        <input  
          type="text"  
          name="name"  
          value={newSource.name || ''}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="form-group">  
        <label>System Prompt</label>  
        <textarea  
          name="systemPrompt"  
          value={newSource.systemPrompt || ''}  
          onChange={handleInputChange}  
          className="form-control system-prompt-text-area"  
        />  
      </div>  
      <div className="form-group">  
        <label>RAG Service</label>  
        <select name="type" value={newSource.type || ''} onChange={handleTypeChange}>  
          <option value="SystemDefault">System Default</option>  
          <option value="CustomHttp">Custom HTTP</option>  
        </select>  
      </div>  
      {!isDefaultType && (  
        <>  
          <div className="form-group">  
            <label>Endpoint</label>  
            <input  
              type="text"  
              name="customHttpRagServiceConfig.endpointUrl"  
              value={newSource.customHttpRagServiceConfig?.endpointUrl || ''}  
              onChange={handleNestedInputChange}  
            />  
          </div>  
          <div className="form-group">  
            <label>Bearer Authentication Token</label>  
            <input  
              type="text"  
              name="customHttpRagServiceConfig.bearerToken"  
              value={newSource.customHttpRagServiceConfig?.bearerToken || ''}  
              onChange={handleNestedInputChange}  
            />  
          </div>  
          <div className="form-group">  
            <label>Max Results</label>  
            <input  
              type="number"  
              name="customHttpRagServiceConfig.maxResults"  
              value={newSource.customHttpRagServiceConfig?.maxResults || 3}  
              onChange={handleNestedInputChange}  
            />  
          </div>  
        </>  
      )}  
      <div className="form-group">  
        <label>Indexing Strategy  
          <Tooltip placement="right-start" content="AI Search Indexer: Built-in Azure AI Search indexing functionality - preferable as a low cost solution. Document Intelligence: detailed, more powerful document analysis and content extraction. CAUTION - USING DOC INTEL WILL INCUR COSTS OF ~31 USD PER 1,000 PAGES.">  
            <StyledInfoFilled size="16" />  
          </Tooltip>  
        </label>  
        <select name="indexingStrategy" value={newSource.indexingStrategy || 'AiSearchIndexer'} onChange={handleInputChange}>  
          <option value="AiSearchIndexer">AI Search Indexer</option>  
          <option value="DocumentIntelligence">Document Intelligence</option>  
        </select>  
      </div>  
    </div>  
  );  
};  
  
export default DataSourceDetailsTab;  
