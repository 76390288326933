// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-title {  
  margin-left: 0px;  
  font-size: 24px;  
  font-weight: bold;  
  color: #333;  
}  

.tabs {  
  display: flex;  
  margin-bottom: 20px;  
}  
  
.tab {  
  padding: 10px;  
  cursor: pointer;  
  border-bottom: 2px solid transparent;  
}  
  
.tab.active {  
  border-bottom: 2px solid #002677;  
}  
  
.tab-content {  
  display: none;  
  flex-grow: 1;  
  overflow-y: auto; /* Ensure content is scrollable */  
}  
  
.tab-content.active {  
  display: block;  
}  `, "",{"version":3,"sources":["webpack://./src/pages/DataSources/DataSourceFormPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB,EAAE,iCAAiC;AACrD;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".form-title {  \r\n  margin-left: 0px;  \r\n  font-size: 24px;  \r\n  font-weight: bold;  \r\n  color: #333;  \r\n}  \r\n\r\n.tabs {  \r\n  display: flex;  \r\n  margin-bottom: 20px;  \r\n}  \r\n  \r\n.tab {  \r\n  padding: 10px;  \r\n  cursor: pointer;  \r\n  border-bottom: 2px solid transparent;  \r\n}  \r\n  \r\n.tab.active {  \r\n  border-bottom: 2px solid #002677;  \r\n}  \r\n  \r\n.tab-content {  \r\n  display: none;  \r\n  flex-grow: 1;  \r\n  overflow-y: auto; /* Ensure content is scrollable */  \r\n}  \r\n  \r\n.tab-content.active {  \r\n  display: block;  \r\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
