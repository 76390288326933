import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { fetchOrganizations, deleteOrganization } from '../../services/organization';  
import { FaEdit, FaTrash } from 'react-icons/fa';  
import './OrganizationsContentPage.css';  
import { Button } from "@uitk/react";  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const OrganizationsContentPage = ({ selectedOrg }) => {  
    const [organizations, setOrganizations] = useState([]);  
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
    const [organizationToDelete, setOrganizationToDelete] = useState(null);  
    const navigate = useNavigate();  
  
    const fetchAllOrganizations = async () => {  
        const organizations = await fetchOrganizations();  
        setOrganizations(organizations);  
    };  
  
    useEffect(() => {  
        fetchAllOrganizations();  
    }, []);  
  
    const handleEditClick = (id) => {  
        navigate(`/organizations/edit/${id}`);  
    };  
    
    const handleDeleteClick = (organization) => {  
        setOrganizationToDelete(organization);  
        setIsDeleteDialogOpen(true);  
    };  
    
    const handleDeleteDialogClose = () => {  
        setIsDeleteDialogOpen(false);  
    };  
    
    const handleDeleteDialogConfirm = async () => {
        await handleDelete(organizationToDelete.id);  
        setIsDeleteDialogOpen(false);  
        setOrganizationToDelete(null);  
    };  

    const handleDelete = async (id) => {  
        await deleteOrganization(id);  
        setOrganizations(organizations.filter(org => org.id !== id));  
    };  

    return (  
        <div className="page-container">  
            <div className="page-header"> 
                <h2>Organizations</h2>
                <div className="page-title-description">Top-level entities that provide a separation layer for teams and data sources.</div>
            </div>
            <div className="page-upper-body">  
                <Button onPress={() => navigate('/organizations/new')}>Add new</Button>
            </div>  
            <div className="page-body">  
                <table>  
                    <thead>  
                        <tr>  
                            <th>Name</th>  
                            <th>Number of Users</th>  
                            <th  style={{ width: '90px' }}>Actions</th>  
                        </tr>  
                    </thead>  
                    <tbody>  
                        {organizations.map((organization) => (  
                            <tr key={organization.id}>  
                                <td>{organization.name}</td>  
                                <td>{organization.users.length}</td>  
                                <td className="action-buttons-cell">  
                                    <button className="action-button" onClick={() => handleEditClick(organization.id)}>  
                                        <FaEdit />  
                                    </button>  
                                    <button className="action-button" onClick={() => handleDeleteClick(organization)}>  
                                        <FaTrash />  
                                    </button>  
                                </td>  
                            </tr>  
                        ))}  
                    </tbody>  
                </table>  
            </div>
        {isDeleteDialogOpen && (  
            <ConfirmationDialog  
            open={isDeleteDialogOpen}  
            onClose={handleDeleteDialogClose}  
            onConfirm={handleDeleteDialogConfirm}  
            message={`Are you sure you want to delete Organization '${organizationToDelete.name}'? This action cannot be undone.`}
            title="Delete Organization?"
            />  
        )}
        </div>  
    );  
};  
  
export default OrganizationsContentPage;  
