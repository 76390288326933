// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    height: 100vh; 
    background-color: #f0f0f0; 
  }  
    
  .login-box {  
    background: #fff;  
    padding: 40px;  
    border-radius: 8px;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    display: flex;  
    flex-direction: column;  
    width: 100%;  
    max-width: 320px; 
    text-align: center; 
  }  
    
  .login-title {  
    font-size: 24px;  
    font-weight: bold;  
    margin-bottom: 20px;
    color: #333;
  }  
      
  .login-form {  
    display: flex;  
    flex-direction: column;  
  }  
    `, "",{"version":3,"sources":["webpack://./src/pages/Login/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB","sourcesContent":[".login-container {  \r\n    display: flex;  \r\n    justify-content: center;  \r\n    align-items: center;  \r\n    height: 100vh; \r\n    background-color: #f0f0f0; \r\n  }  \r\n    \r\n  .login-box {  \r\n    background: #fff;  \r\n    padding: 40px;  \r\n    border-radius: 8px;  \r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  \r\n    display: flex;  \r\n    flex-direction: column;  \r\n    width: 100%;  \r\n    max-width: 320px; \r\n    text-align: center; \r\n  }  \r\n    \r\n  .login-title {  \r\n    font-size: 24px;  \r\n    font-weight: bold;  \r\n    margin-bottom: 20px;\r\n    color: #333;\r\n  }  \r\n      \r\n  .login-form {  \r\n    display: flex;  \r\n    flex-direction: column;  \r\n  }  \r\n    "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
