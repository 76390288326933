// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-container {  
    padding: 20px;  
    background-color: #ffffff;  
    border-radius: 10px;  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
  }  
    
  .contact-form {  
    margin-top: 20px;  
  }  
    
  .contact-form input,  
  .contact-form textarea {  
    width: 100%;  
    padding: 10px;  
    margin-bottom: 10px;  
    border: 1px solid #ddd;  
    border-radius: 5px;  
    box-sizing: border-box;  
  }  
    
  .contact-form button {  
    padding: 10px 20px;  
    background-color: #002677;  
    color: white;  
    border: none;  
    border-radius: 5px;  
    cursor: pointer;  
  }  
    `, "",{"version":3,"sources":["webpack://./src/pages/Resources/SupportPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;EAClB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".help-container {  \r\n    padding: 20px;  \r\n    background-color: #ffffff;  \r\n    border-radius: 10px;  \r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  \r\n  }  \r\n    \r\n  .contact-form {  \r\n    margin-top: 20px;  \r\n  }  \r\n    \r\n  .contact-form input,  \r\n  .contact-form textarea {  \r\n    width: 100%;  \r\n    padding: 10px;  \r\n    margin-bottom: 10px;  \r\n    border: 1px solid #ddd;  \r\n    border-radius: 5px;  \r\n    box-sizing: border-box;  \r\n  }  \r\n    \r\n  .contact-form button {  \r\n    padding: 10px 20px;  \r\n    background-color: #002677;  \r\n    color: white;  \r\n    border: none;  \r\n    border-radius: 5px;  \r\n    cursor: pointer;  \r\n  }  \r\n    "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
