import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { fetchDataSources, deleteDataSource, fetchDataSource } from '../../services/datasource';  
import { fetchTeams } from '../../services/team';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { FaEdit, FaTrash, FaSpinner, FaCog, FaCheck, FaQuestionCircle, FaExclamationCircle } from 'react-icons/fa';  
import './DataSourcesContentPage.css';  
import DataSourceFileListPopup from '../../components/DataSources/DataSourceFileListPopup';  
import { Button, Tooltip } from "@uitk/react";  
import SplashPage from '../Splash/SplashPage';  
import styled, { css } from "styled-components";  
import { InfoIcon } from "@uitk/react-icons";  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const DataSourcesContentPage = ({ user, selectedOrg }) => {  
  if (selectedOrg === undefined) {  
    return (  
      <SplashPage message="This page will be available once an Organization is selected." />  
    );  
  }  
  
  const [dataSources, setDataSources] = useState([]);  
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [selectedDataSourceId, setSelectedDataSourceId] = useState(null);  
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
  const [dataSourceToDelete, setDataSourceToDelete] = useState(null);  
  const navigate = useNavigate();  
  
  const fetchDataSourcesList = async () => {  
    try {  
      const response = await fetchDataSources(selectedOrg.id);  
      setDataSources(response);  
    } catch (error) {  
      console.error('Error fetching data sources:', error);  
    }  
  };  
  
  useEffect(() => {  
    if (selectedOrg.id) {  
      fetchDataSourcesList();  
    }  
  }, [selectedOrg.id]);  
  
  useEffect(() => {  
    const intervalId = setInterval(() => {  
      if (dataSources.some(source => source.fileStatus !== 'Ready')) {  
        fetchDataSourcesList();  
      }  
    }, 5000);  
  
    return () => clearInterval(intervalId);  
  }, [dataSources]);  
  
  const handleDelete = async (dataSourceId) => {  
    await deleteDataSource(selectedOrg.id, dataSourceId);  
    fetchDataSourcesList();  
  };  
  
  const handleEditClick = async (id) => {  
    const dataSource = await fetchDataSource(id);  
    const teams = await fetchTeams(selectedOrg.id);  
    navigate(`/datasources/edit/${id}`, { state: { initialDataSource: dataSource, availableTeams: teams } });
  };  
  
  const handleAddNew = async () => {  
    const initialDataSource = {  
      id: 0,  
      name: '',  
      type: 'SystemDefault',  
      fileMetadata: [],  
      systemPrompt: 'You are an AI assistant that helps users with questions. ' +  
        'Your responses are concise and you aim to keep them under 200 characters when the user question is straightforward enough. ' +  
        'You use the in-context data provided by the user as your primary source of information for generating responses. ' +  
        'If more than one document is provided, always prioritize the information from the first documents over subsequent ones.',  
      users: [],  
      teams: [],  
      organizationId: selectedOrg.id  
    };  
    const teams = await fetchTeams(selectedOrg.id);  
    navigate('/datasources/new', { state: { initialDataSource, availableTeams: teams } });  
  };  
  
  const renderIndexingStatus = (status, errorMessage) => {  
    switch (status) {  
      case 'Uploading':  
        return <><FaSpinner className="file-status icon-spin" /> {status}</>;  
      case 'Indexing':  
      case 'Analyzing':  
        return <><FaCog className="file-status icon-spin" /> {status}</>;  
      case 'Ready':  
        return <><FaCheck className="file-status" /> {status}</>;  
      case 'Error':  
        return <><FaExclamationCircle className="file-status" /> {status}: {errorMessage}</>;  
      default:  
        return <><FaQuestionCircle className="file-status" /> {status}</>;  
    }  
  };  
  
  const handleFileCountClick = (dataSourceId) => {  
    setSelectedDataSourceId(dataSourceId);  
    setIsModalOpen(true);  
  };  
  
  const handleDeleteClick = (dataSource) => {  
    setDataSourceToDelete(dataSource);  
    setIsDeleteDialogOpen(true);  
  };  
  
  const handleDeleteDialogClose = () => {  
    setIsDeleteDialogOpen(false);  
  };  
  
  const handleDeleteDialogConfirm = async () => {
    await handleDelete(dataSourceToDelete.id);  
    setIsDeleteDialogOpen(false);  
    setDataSourceToDelete(null);  
  };  
  
  const StyledInfoFilled = styled(InfoIcon)`  
    ${({ theme }) => css`  
      fill: ${theme.UitkTooltipColorIndicator};  
    `}  
  `;  
  
  return (  
    <div className="page-container">  
      <div className="page-header">  
        <PageTitle  
          elements={[{ name: selectedOrg.name, route: "/" },  
          { name: "Data Sources", route: "/datasources" }]}  
        />  
        <div className="page-title-description">Repositories of information that can be utilized by AI to generate answers for user queries.</div>  
      </div>  
      <div className="page-upper-body">  
        {user.isSystemAdmin || user.isOrganizationOwner ? (  
          <Button onPress={handleAddNew}>Add new</Button>  
        ) : null}  
      </div>  
      <div className="page-body">  
        <table>  
          <thead>  
            <tr>  
              <th>Name</th>  
              <th>RAG Service</th>  
              <th>Files</th>  
              <div className="title-with-tooltip">  
                <th>Status</th>  
                <Tooltip placement="right-start" content="Uploading: Files are being uploaded to cloud storage.  
                  Indexing: The RAG service is processing the files previously uploaded to cloud storage.  
                  Ready: The Data Source is ready to be used in Chat.">  
                  <StyledInfoFilled size="16" />  
                </Tooltip>  
              </div>  
              <th style={{ width: '90px' }}>Actions</th>  
            </tr>  
          </thead>  
          <tbody>  
            {dataSources.map((dataSource) => (  
              <tr key={dataSource.id}>  
                <td>{dataSource.name}</td>  
                <td>{dataSource.type}</td>  
                <td>  
                  <a href="#" onClick={() => handleFileCountClick(dataSource.id)}>  
                    {dataSource.fileCount}  
                  </a>  
                </td>  
                <td>{renderIndexingStatus(dataSource.fileStatus, dataSource.fileErrorMessage)}</td>  
                <td className="action-buttons-cell">  
                  <button className="action-button" onClick={() => handleEditClick(dataSource.id)}>  
                    <FaEdit />  
                  </button>  
                  {user.isSystemAdmin || user.isOrganizationOwner ? (  
                    <button className="action-button" onClick={() => handleDeleteClick(dataSource)}>  
                      <FaTrash />  
                    </button>  
                  ) : null}  
                </td>  
              </tr>  
            ))}  
          </tbody>  
        </table>  
      </div>  
      <DataSourceFileListPopup  
        dataSourceId={selectedDataSourceId}  
        isOpen={isModalOpen}  
        onClose={() => setIsModalOpen(false)}  
      />  
      {isDeleteDialogOpen && (  
        <ConfirmationDialog  
          open={isDeleteDialogOpen}  
          onClose={handleDeleteDialogClose}  
          onConfirm={handleDeleteDialogConfirm}  
          message={`Are you sure you want to delete Data Source '${dataSourceToDelete.name}'? This action cannot be undone.`}
          title="Delete Data Source?"
        />  
      )}
    </div>  
  );  
};  
  
export default DataSourcesContentPage;  
