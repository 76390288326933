// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Insights/InsightsContentPage.css */  
  
.insights-metrics {  
    display: flex;  
    flex-wrap: wrap;  
    gap: 20px;  
}  
  
.insight-card {  
    background-color: #f9f9f9;  
    border: 1px solid #ddd;  
    border-radius: 5px;  
    padding: 20px;  
    flex: 1 1 calc(33.333% - 40px);  
    box-sizing: border-box;  
    text-align: center;  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
}  
  
.insight-card h3 {  
    margin-top: 0;  
    margin-bottom: 10px;  
    font-size: 18px;  
    color: #333;  
}  
  
.insight-card p {  
    margin: 0;  
    font-size: 24px;  
    font-weight: bold;  
    color: #002677;  
}  
  
.scrollable-table {  
    max-height: 150px;  
    overflow-y: auto;  
    text-align: left;  
    margin-top: 10px;  
}  
  
.scrollable-table table {  
    width: 100%;  
}  
  
.scrollable-table th, .scrollable-table td {  
    padding: 8px;  
    text-align: left;  
}  
  
.more-link {  
    display: block;  
    margin: 10px auto;  
    text-align: center;  
    color: #002677;  
    cursor: pointer;  
    text-decoration: none;  
}  
  
.more-link:hover {  
    text-decoration: underline;  
}  
`, "",{"version":3,"sources":["webpack://./src/pages/Insights/InsightsContentPage.css"],"names":[],"mappings":"AAAA,+CAA+C;;AAE/C;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,SAAS;IACT,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/pages/Insights/InsightsContentPage.css */  \r\n  \r\n.insights-metrics {  \r\n    display: flex;  \r\n    flex-wrap: wrap;  \r\n    gap: 20px;  \r\n}  \r\n  \r\n.insight-card {  \r\n    background-color: #f9f9f9;  \r\n    border: 1px solid #ddd;  \r\n    border-radius: 5px;  \r\n    padding: 20px;  \r\n    flex: 1 1 calc(33.333% - 40px);  \r\n    box-sizing: border-box;  \r\n    text-align: center;  \r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  \r\n}  \r\n  \r\n.insight-card h3 {  \r\n    margin-top: 0;  \r\n    margin-bottom: 10px;  \r\n    font-size: 18px;  \r\n    color: #333;  \r\n}  \r\n  \r\n.insight-card p {  \r\n    margin: 0;  \r\n    font-size: 24px;  \r\n    font-weight: bold;  \r\n    color: #002677;  \r\n}  \r\n  \r\n.scrollable-table {  \r\n    max-height: 150px;  \r\n    overflow-y: auto;  \r\n    text-align: left;  \r\n    margin-top: 10px;  \r\n}  \r\n  \r\n.scrollable-table table {  \r\n    width: 100%;  \r\n}  \r\n  \r\n.scrollable-table th, .scrollable-table td {  \r\n    padding: 8px;  \r\n    text-align: left;  \r\n}  \r\n  \r\n.more-link {  \r\n    display: block;  \r\n    margin: 10px auto;  \r\n    text-align: center;  \r\n    color: #002677;  \r\n    cursor: pointer;  \r\n    text-decoration: none;  \r\n}  \r\n  \r\n.more-link:hover {  \r\n    text-decoration: underline;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
