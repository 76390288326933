// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-prompt-text-area {  
  width: 99%;
  height: 73px !important;  
  resize: both;
  overflow-y: auto;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DataSourceDetailsTab.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".system-prompt-text-area {  \r\n  width: 99%;\r\n  height: 73px !important;  \r\n  resize: both;\r\n  overflow-y: auto;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
