import { axiosInstance } from './config';  
  
export const fetchOrganizations = async () => {  
  try {  
    const response = await axiosInstance.get(`/api/organization/all`);  
    return response.data;  
  } catch (error) {  
    console.error('Error fetching organizations:', error);  
    throw error;  
  }  
};  
  
export const fetchOrganization = async (id) => {  
  try {  
    const response = await axiosInstance.get(`/api/organization/${id}`);  
    return response.data;  
  } catch (error) {  
    console.error('Error fetching organization:', error);  
    throw error;  
  }  
};  
  
export const saveOrganization = async (organization) => {  
  try {  
    if (organization.id) {  
      await axiosInstance.put(`/api/organization/${organization.id}`, organization);  
    } else {  
      await axiosInstance.post(`/api/organization`, organization);  
    }  
  } catch (error) {  
    console.error('Error saving organization:', error);  
    throw error;  
  }  
};  
  
export const deleteOrganization = async (id) => {  
  try {  
    await axiosInstance.delete(`/api/organization/${id}`);  
  } catch (error) {  
    console.error('Error deleting organization:', error);  
    throw error;  
  }  
};  
  
export const getStoredOrg = () => {  
  const org = localStorage.getItem('selectedOrg');  
  return org && org !== 'undefined' ? JSON.parse(org) : {};  
};  
