import React, { useState, useEffect } from 'react';  
import { useParams, useNavigate } from 'react-router-dom';  
import { fetchUser, saveUser } from '../../services/user';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { Button } from "@uitk/react";  
import './UserFormPage.css';  
  
const UserFormPage = () => {  
  const { username } = useParams();  
  const navigate = useNavigate();  
  const [userData, setUserData] = useState({ username: '', firstName: '', lastName: '', email: '', password: '' });  
  
  useEffect(() => {  
    const fetchUserData = async () => {  
      if (username) {  
        try {  
          const userData = await fetchUser(username);  
          setUserData(userData);  
        } catch (error) {  
          console.error('Error fetching user:', error);  
        }  
      }  
    };  
    fetchUserData();  
  }, [username]);  
    
  const handleInputChange = (e) => {  
    const { name, value } = e.target;  
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));  
  };  
    
  const handleSave = async () => {  
    try {  
      let isEdit = false;
      if (username) {
        isEdit = true;
      }
      await saveUser(userData, isEdit);  
      navigate('/users');  
    } catch (error) {  
      console.error('Error saving user:', error);  
    }  
  };  
  
  return (  
    <div className="page-container">  
      <PageTitle  
        elements={[  
          { name: "Users", route: "/users" },  
          { name: userData.username ? userData.username : 'New', route: "#" }  
        ]}  
      />  
      <div className="form-group">  
        <label>Username</label>  
        <input  
          type="text"  
          name="username"  
          value={userData.username}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="form-group">  
        <label>First Name</label>  
        <input  
          type="text"  
          name="firstName"  
          value={userData.firstName}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="form-group">  
        <label>Last Name</label>  
        <input  
          type="text"  
          name="lastName"  
          value={userData.lastName}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="form-group">  
        <label>Email</label>  
        <input  
          type="text"  
          name="email"  
          value={userData.email}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="form-group">  
        <label>Password</label>  
        <input  
          type="password"  
          name="password"  
          value={userData.password}  
          onChange={handleInputChange}  
        />  
      </div>  
      <div className="button-container">  
        <Button onPress={handleSave}>Save</Button>  
        <Button onPress={() => navigate('/users')}>Cancel</Button>  
      </div>  
    </div>  
  );  
};  
  
export default UserFormPage;  
