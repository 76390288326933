import React, { useState } from 'react';  
import './DocumentationPage.css';  
  
const DocumentationPage = () => {  
  const [searchTerm, setSearchTerm] = useState('');  
  
  const handleSearchChange = (event) => {  
    setSearchTerm(event.target.value);  
  };  
  
  const documentationSections = [  
    {  
      title: 'Getting Started',  
      content: (  
        <>  
          <p>Welcome to Document Searcher! To get started, log in with your credentials. If you don't have an account, please contact your administrator.</p>  
          <ul>  
            <li>Navigate to the login page.</li>  
            <li>Enter your username and password.</li>  
            <li>Click the "Login" button.</li>  
            <li>If you encounter any issues, contact support.</li>  
          </ul>  
        </>  
      ),  
    },  
    {  
      title: 'Using the Chat',  
      content: (  
        <>  
          <p>To interact with the assistant, navigate to the Chat section from the sidebar. Type your queries in the input box and press Enter or click Send.</p>  
          <ul>  
            <li>Open the chat interface.</li>  
            <li>Type your question or command in the input box.</li>  
            <li>Press Enter or click the "Send" button.</li>  
            <li>Wait for the assistant's response.</li>  
          </ul>  
        </>  
      ),  
    },  
    {  
      title: 'Managing Data Sources',  
      content: (  
        <>  
          <p>Admins can add, edit, and delete data sources. Navigate to the Data Sources section from the sidebar to manage your data sources.</p>  
          <ul>  
            <li>Go to the Data Sources section.</li>  
            <li>Click "Add" to create a new data source.</li>  
            <li>Fill in the required details and save.</li>  
            <li>To edit or delete a data source, use the corresponding options next to each entry.</li>  
          </ul>  
        </>  
      ),  
    },  
  ];  
  
  const filteredSections = documentationSections.filter(section =>  
    section.title.toLowerCase().includes(searchTerm.toLowerCase())  
  );  
  
  return (  
    <div className="documentation-container">  
      <h2>Documentation</h2>  
      <div className="search-bar">  
        <input  
          type="text"  
          placeholder="Search..."  
          value={searchTerm}  
          onChange={handleSearchChange}  
        />  
      </div>  
      {filteredSections.map((section, index) => (  
        <div key={index} className="documentation-section">  
          <h3>{section.title}</h3>  
          {section.content}  
        </div>  
      ))}  
    </div>  
  );  
};  
  
export default DocumentationPage;  
