import React from 'react';  
import './SupportPage.css';  
  
const SupportPage = () => {  
  return (  
    <div className="help-container">  
      <div className="contact-form">  
        <h3>Contact Us</h3>  
        <input type="text" placeholder="Your Name" />  
        <input type="email" placeholder="Your Email" />  
        <textarea placeholder="Your Message" rows="4"></textarea>  
        <button>Submit</button>  
      </div>  
    </div>  
  );  
};  
  
export default SupportPage;  
