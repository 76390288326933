// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/PageTitle/PageTitle.css */  
.page-title-header-section {  
  display: flex;  
  align-items: center;  
  margin-bottom: 20px;  
}  
.page-title-section-container {  
  display: flex;  
  align-items: center;  
  padding: 8px 12px;  
  border-radius: 8px;  
  background-color: #f1f1f158;  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
  margin-right: 0px;  
}  
.page-title-organization-name,  
.page-title-page-title {  
  font-size: 18px;  
  font-weight: 500;  
  color: #002677;  
  font-weight: bold;  
}  
.page-title-page-title.level-1 {  
  color: #002677;  
  cursor: pointer;  
}  
.page-title-page-title.level-2 {  
  color: #002677;  
}  
.page-title-page-title.level-3 {  
  color: #002677;  
}  
.page-title-page-title.level-4,  
.page-title-page-title.level-5,  
.page-title-page-title.level-6 {  
  color: #002677;  
}  
.page-title-separator {  
  margin: 0 5px;  
  font-size: 16px;  
  color: #002677;  
}  `, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,2BAA2B;EAC3B,wCAAwC;EACxC,iBAAiB;AACnB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;;;EAGE,cAAc;AAChB;AACA;EACE,aAAa;EACb,eAAe;EACf,cAAc;AAChB","sourcesContent":["/* src/components/PageTitle/PageTitle.css */  \r\n.page-title-header-section {  \r\n  display: flex;  \r\n  align-items: center;  \r\n  margin-bottom: 20px;  \r\n}  \r\n.page-title-section-container {  \r\n  display: flex;  \r\n  align-items: center;  \r\n  padding: 8px 12px;  \r\n  border-radius: 8px;  \r\n  background-color: #f1f1f158;  \r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  \r\n  margin-right: 0px;  \r\n}  \r\n.page-title-organization-name,  \r\n.page-title-page-title {  \r\n  font-size: 18px;  \r\n  font-weight: 500;  \r\n  color: #002677;  \r\n  font-weight: bold;  \r\n}  \r\n.page-title-page-title.level-1 {  \r\n  color: #002677;  \r\n  cursor: pointer;  \r\n}  \r\n.page-title-page-title.level-2 {  \r\n  color: #002677;  \r\n}  \r\n.page-title-page-title.level-3 {  \r\n  color: #002677;  \r\n}  \r\n.page-title-page-title.level-4,  \r\n.page-title-page-title.level-5,  \r\n.page-title-page-title.level-6 {  \r\n  color: #002677;  \r\n}  \r\n.page-title-separator {  \r\n  margin: 0 5px;  \r\n  font-size: 16px;  \r\n  color: #002677;  \r\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
