// File: src/App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Callback from "./components/Authentication/Callback";
import Error from "./components/Authentication/Error";
import Layout from "./components/Layout/Layout";
import { axiosInstance } from "./services/config";
import { getUser, login } from "./services/auth";
import { fetchUser } from "./services/user";
import SplashPage from "./pages/Splash/SplashPage";
import "./App.css";
import logo from "./assets/images/optum-logo.png";
import HorizontalBarSpinner from "./components/Common/HorizontalBarSpinner";
import SilentRenew from "./components/Authentication/SilentRenew";
import ErrorNotification from "./components/Common/ErrorNotification";

const AppInner = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const location = useLocation();

  // Listen for axios errors globally
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (err) => {
        const message = (err.response?.data?.message || err.response?.data || err?.toString() || "An unknown error occurred").slice(0, 1000);
        console.log("Axios error caught in App.js:", message);
        setError(message);
        setErrorOpen(true);
        return Promise.reject(err);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, []);

  // OIDC auto-login (but do not force login if we're on /callback or /error)
  useEffect(() => {
    getUser().then((userFromOidc) => {
      if (!userFromOidc) {
        if (location.pathname !== "/callback" && location.pathname !== "/error") {
          login();
        }
      } else {
        const oidcUsername = userFromOidc.profile.email;
        if (!oidcUsername) {
          setUser({});
          return;
        }
        fetchUser(oidcUsername)
          .then((userFromServer) => {
            // Use OIDC profile values as fallback for firstName and lastName
            userFromServer.firstName = userFromServer.firstName || userFromOidc.profile.given_name;
            userFromServer.lastName = userFromServer.lastName || userFromOidc.profile.family_name;

            // Also store the list of oidc groups in the user object, which can be useful for display purposes
            userFromServer.msad_groups = userFromOidc.profile.msad_groups;

            // Save to localStorage and state
            localStorage.setItem("user", JSON.stringify(userFromServer));
            setUser(userFromServer);
          })
          .catch((err) => {
            console.error("Error fetching server user details: ", err);
            if (err.response && err.response.status === 204) {
              setUser({ exists: false });
            }
          });
      }
    });
  }, [location]);

  // Define a function to dismiss the error
  const dismissError = () => setErrorOpen(false);

  return (
    <>
      {/* Always render the error notification if there's an error */}
      <ErrorNotification error={errorOpen ? error : null} onDismiss={dismissError} />

      {/* Conditional Rendering Based on Route and User State */}
      {location.pathname === "/callback" || location.pathname === "/silentreview" || location.pathname === "/error" ? (
        <>
          <Routes>
            <Route path="/callback" element={<Callback />} />
            <Route path="/silentrenew" element={<SilentRenew />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </>
      ) : user?.exists === false ? (
        <SplashPage message="You are not part of any organizations yet. Please contact your system administrator." />
      ) : !user ? (
        <div className="loading-container">
          <img src={logo} alt="Optum Logo" className="loading-logo" />
          <div className="loading-app-name">Document Search</div>
          <HorizontalBarSpinner />
        </div>
      ) : (
        <Routes>
          <Route path="*" element={<Layout user={user} setUser={setUser} />} />
        </Routes>
      )}
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<AppInner />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
