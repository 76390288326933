import React from 'react';  
import { FaTimes } from 'react-icons/fa';  
import './DatasetFileIndexDocumentListPopup.css';  
  
const DocumentListPopup = ({ isOpen, onClose, documents }) => {  
    if (!isOpen) return null;  
  
    const totalChunks = Math.max(...documents.map(doc => doc.chunk));  
    const sortedDocuments = [...documents].sort((a, b) => a.chunk - b.chunk);  
  
    const renderContentWithLineBreaks = (content) => {  
        return content.split('\n').map((line, index) => (  
            <React.Fragment key={index}>  
                {line}  
                <br />  
            </React.Fragment>  
        ));  
    };  
  
    return (  
        <div className="popup-overlay">  
            <div className="document-list-popup-content">  
                <button className="close-button" onClick={onClose}>  
                    <FaTimes />  
                </button>  
                <h2>Index Documents</h2>  
                <div className="document-table-container">  
                    <table className="document-table">  
                        <thead>  
                            <tr>  
                                <th>Chunk</th>  
                                <th>Documents</th>  
                            </tr>  
                        </thead>  
                        <tbody>  
                            {sortedDocuments.map((doc, index) => (  
                                <tr key={index}>  
                                    <td>{`${doc.chunk} of ${totalChunks}`}</td>  
                                    <td>{renderContentWithLineBreaks(doc.content)}</td>  
                                </tr>  
                            ))}  
                        </tbody>  
                    </table>  
                </div>  
            </div>  
        </div>  
    );  
};  
  
export default DocumentListPopup;  
