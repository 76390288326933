import React, { useState } from 'react';  
import ReactMarkdown from 'react-markdown';  
import './BotMessage.css';  
import Modal from './Modal';  
import { fetchDocument } from '../../services/datasource';  
import { FaExternalLinkAlt, FaFileAlt, FaCopy, FaRobot } from 'react-icons/fa';  

const BotMessage = ({ content, sources, dataSourceId, isTyping, handleReSubmit }) => {  
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [fileUrl, setFileUrl] = useState('');  
    const [showNotification, setShowNotification] = useState(false);  
  
    const openFileModal = async (fileName) => {  
        try {  
            const fileBlob = await fetchDocument(dataSourceId, fileName);  
            const fileUrl = URL.createObjectURL(fileBlob);  
            const fileExtension = fileName.split('.').pop().toLowerCase();  
  
            if (fileExtension === 'pdf') {  
                setFileUrl(fileUrl);  
                setIsModalOpen(true);  
            } else {  
                const link = document.createElement('a');  
                link.href = fileUrl;  
                link.download = fileName;  
                document.body.appendChild(link);  
                link.click();  
                document.body.removeChild(link);  
                URL.revokeObjectURL(fileUrl);  
            }  
        } catch (error) {  
            console.error('Error fetching document:', error);  
        }  
    };  
  
    const copyToClipboard = () => {  
        navigator.clipboard.writeText(content).then(() => {  
            setShowNotification(true);  
            setTimeout(() => {  
                setShowNotification(false);  
            }, 1500);  
        });  
    };  
  
    const renderers = {  
        paragraph: ({ children }) => <>{children}</>,  
    };  
  
    const handleRetry = () => {
        handleReSubmit();
    };  

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    return (  
        <div className="bot-message">  
            <div className="icon">  
                <FaRobot />  
            </div>  
            <div className="bot-message-content">  
                <div className="message-text">  
                    {isTyping ? (  
                        <div className="typing">  
                            <span className="dot"></span>  
                            <span className="dot"></span>  
                            <span className="dot"></span>  
                        </div>  
                    ) : (  
                        content === 'error' ? (  
                            <div>
                                <span>An error occurred. Please try again.</span>
                                <br></br>
                                <button className="retry-button" onClick={handleRetry}>Retry</button>
                            </div>
                        ) : (  
                            <div>
                                <ReactMarkdown components={renderers}>{String(content)}</ReactMarkdown> 
                                <div className="copy-container">  
                                    <FaCopy className="copy-icon" onClick={copyToClipboard} />  
                                </div>                                  
                            </div>                            
                        )  
                    )} 
                </div>  
                {sources && sources.length > 0 && (  
                    <div className="bot-message-sources">  
                        {sources.map((source, index) => (  
                            isValidUrl(source.url) ? (  
                                <a key={index} className="source-link" href={source.url} target="_blank" rel="noopener noreferrer">  
                                    <div className="source-box">  
                                        <FaExternalLinkAlt className="file-icon" />  
                                        <span className="file-name">{source.title}</span>  
                                    </div>  
                                </a>  
                            ) : (  
                                <button key={index} className="source-link" onClick={() => openFileModal(source.fileName)}>  
                                    <div className="source-box">  
                                        <FaFileAlt className="file-icon" />  
                                        <span className="file-name">{source.fileName}</span>  
                                    </div>  
                                </button>  
                            )  
                        ))}  
                    </div>  
                )}  
            </div>  
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>  
                <div className="iframe-container">  
                    <iframe src={fileUrl} frameBorder="0" style={{ width: '100%', height: '100%' }}></iframe>  
                </div>  
            </Modal>  
        </div>  
    );  
};  
  
export default BotMessage;  
