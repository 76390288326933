import React, { useState, useEffect } from 'react';  
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';  
import Layout from './components/Layout/Layout';  
import { getStoredUser, fetchUser } from './services/user';  
import LoginPage from './pages/Login/LoginPage';  
import { getStoredOrg } from './services/organization';  
import { Notification } from "@uitk/react";  
import { axiosInstance } from './services/config';  
import './App.css'; // Import CSS for centering the popup and overlay  
  
const App = () => {  
  const [user, setUser] = useState(getStoredUser());  
  const [isAuthenticated, setIsAuthenticated] = useState(!!getStoredUser());  
  const [error, setError] = useState(null);  
  const [errorOpen, setErrorOpen] = useState(false);  
  
  useEffect(() => {  
    const initializeUser = async () => {  
      const storedUser = getStoredUser();  
      if (storedUser) {  
        try {  
          const currentUser = await fetchUser(storedUser.username);  
          localStorage.setItem('user', JSON.stringify(currentUser));  
          setUser(currentUser);  
          setIsAuthenticated(true);  
        } catch (error) {  
          console.error('Error fetching current user:', error);  
          setError('Error fetching current user');  
          setErrorOpen(true);  
          setIsAuthenticated(false);  
        }  
      } else {  
        setIsAuthenticated(false);  
      }  
    };  
    initializeUser();  
  }, []);  
  
  useEffect(() => {  
    const interceptor = axiosInstance.interceptors.response.use(  
      response => response,  
      error => {
        const message = (error.response?.data?.message || error.response?.data || error?.toString() || 'An unkown error occurred').slice(0, 1000);  
        setError(message);  
        setErrorOpen(true);  
        return Promise.reject(error);  
      }  
    );  
    
    return () => {  
      axiosInstance.interceptors.response.eject(interceptor);  
    };  
  }, []);   
  
  const handleLogout = () => {  
    setUser(null);  
    localStorage.removeItem('user');  
    localStorage.removeItem('selectedOrg');  
    localStorage.removeItem('jwt');      
    
    for (let i = localStorage.length - 1; i >= 0; i--) {  
      const key = localStorage.key(i);  
      if (key.startsWith('chatHistory')) {  
        localStorage.removeItem(key);  
      }  
    }  

    setIsAuthenticated(false);  
  };  
  
  const dismissError = () => setErrorOpen(false);  
  
  return (  
    <BrowserRouter>  
      <div className="app-container">  
        {errorOpen && (  
          <div className="overlay">  
            <div className="notification-popup">  
              <Notification  
                onDismiss={dismissError}  
                buttonText={"Close"}  
                variant={"error"}  
                id={"notification-error"}  
                show={errorOpen}  
                className="mv-m"  
              >  
                {error}  
              </Notification>  
            </div>  
          </div>  
        )}  
        <Routes>  
          <Route path="/login" element={<LoginPage setUser={setUser} setIsAuthenticated={setIsAuthenticated} />} />  
          <Route  
            path="/*"  
            element={  
              isAuthenticated ? (  
                <Layout  
                  user={user}  
                  handleLogout={handleLogout}  
                  setUser={setUser}  
                />  
              ) : (  
                <Navigate to="/login" />  
              )  
            }  
          />  
          <Route path="/" element={isAuthenticated ? <Navigate to="/chat" /> : <Navigate to="/login" />} />  
        </Routes>  
      </div>  
    </BrowserRouter>  
  );  
};  
  
export default App;  
