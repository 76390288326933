// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DocumentListPopup.css */  
.document-list-popup-content {  
  background: white;  
  padding: 20px;  
  border-radius: 10px;  
  width: 80%;  
  max-width: 80%;  
  max-height: 80%;  
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  
  position: relative;  
  display: flex;  
  flex-direction: column;  
}  

.close-button {  
  position: absolute;  
  top: 10px;  
  right: 10px;  
  background: none;  
  border: none;  
  font-size: 1.2rem;  
  cursor: pointer;  
}  

.document-table-container {  
  flex: 1;  
  overflow-y: auto;  
  margin-top: 20px;  
}  

.document-table {  
  width: 100%;  
  border-collapse: collapse;  
}  

.document-table th,  
.document-table td {  
  border: 1px solid #ddd;  
  padding: 8px;  
  text-align: left;  
  vertical-align: top;  
}  

.document-table th {  
  background-color: #fafafa;  
  color: #333;  
}  

.document-table td {  
  background-color: #ffffff;  
  font-size: small;  
}  

.document-table tbody tr:nth-child(odd) td {  
  background-color: #ffffff;
}  

.document-table tbody tr:nth-child(even) td {  
  background-color: #eeeeee;
}  
`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DatasetFileIndexDocumentListPopup.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,cAAc;EACd,eAAe;EACf,yCAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* DocumentListPopup.css */  \r\n.document-list-popup-content {  \r\n  background: white;  \r\n  padding: 20px;  \r\n  border-radius: 10px;  \r\n  width: 80%;  \r\n  max-width: 80%;  \r\n  max-height: 80%;  \r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  \r\n  position: relative;  \r\n  display: flex;  \r\n  flex-direction: column;  \r\n}  \r\n\r\n.close-button {  \r\n  position: absolute;  \r\n  top: 10px;  \r\n  right: 10px;  \r\n  background: none;  \r\n  border: none;  \r\n  font-size: 1.2rem;  \r\n  cursor: pointer;  \r\n}  \r\n\r\n.document-table-container {  \r\n  flex: 1;  \r\n  overflow-y: auto;  \r\n  margin-top: 20px;  \r\n}  \r\n\r\n.document-table {  \r\n  width: 100%;  \r\n  border-collapse: collapse;  \r\n}  \r\n\r\n.document-table th,  \r\n.document-table td {  \r\n  border: 1px solid #ddd;  \r\n  padding: 8px;  \r\n  text-align: left;  \r\n  vertical-align: top;  \r\n}  \r\n\r\n.document-table th {  \r\n  background-color: #fafafa;  \r\n  color: #333;  \r\n}  \r\n\r\n.document-table td {  \r\n  background-color: #ffffff;  \r\n  font-size: small;  \r\n}  \r\n\r\n.document-table tbody tr:nth-child(odd) td {  \r\n  background-color: #ffffff;\r\n}  \r\n\r\n.document-table tbody tr:nth-child(even) td {  \r\n  background-color: #eeeeee;\r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
