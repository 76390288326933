// src/components/PageTitle/PageTitle.js  
import React from 'react';  
import { FaChevronRight } from 'react-icons/fa';  
import { useNavigate } from 'react-router-dom';  
import './PageTitle.css';  
  
const PageTitle = ({ elements }) => {  
    const navigate = useNavigate();  
  
    return (  
        <div className="page-title-header-section">  
            {elements.map((item, index) => (  
                <React.Fragment key={index}>  
                    <div  
                        className="page-title-section-container"  
                        onClick={index === 0 ? () => navigate(item.route) : undefined}  
                    >  
                        <div className={`page-title-page-title level-${index + 1}`}>{item.name}</div>  
                    </div>  
                    {index < elements.length - 1 && <FaChevronRight className="page-title-separator" />}  
                </React.Fragment>  
            ))}  
        </div>  
    );  
};  
  
export default PageTitle;  
