// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-message {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.user-message-content {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    max-width: 70%;
    flex-shrink: 0;
    position: relative;
}

.user-message-text {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 1;
    position: relative;
    margin-right: 50px; /* Add margin to separate text from icon */
}

.user-message p {
    background: #daffda;
    color: rgb(53, 53, 53);
    padding: 10px;
    border-radius: 5px;
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif;    
    margin: 0;
    white-space: pre-wrap;
    flex-shrink: 1;
    text-align: right;
}

.user-message .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    background-color: #3da554;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chat/UserMessage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,2BAA2B;IAC3B,cAAc;IACd,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,kBAAkB,EAAE,0CAA0C;AAClE;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,iCAAiC;IACjC,SAAS;IACT,qBAAqB;IACrB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ","sourcesContent":[".user-message {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.user-message-content {\r\n    display: flex;\r\n    align-items: flex-start;\r\n    flex-direction: row-reverse;\r\n    max-width: 70%;\r\n    flex-shrink: 0;\r\n    position: relative;\r\n}\r\n\r\n.user-message-text {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    flex-shrink: 1;\r\n    position: relative;\r\n    margin-right: 50px; /* Add margin to separate text from icon */\r\n}\r\n\r\n.user-message p {\r\n    background: #daffda;\r\n    color: rgb(53, 53, 53);\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n    word-wrap: break-word;\r\n    font-family: 'Roboto', sans-serif;    \r\n    margin: 0;\r\n    white-space: pre-wrap;\r\n    flex-shrink: 1;\r\n    text-align: right;\r\n}\r\n\r\n.user-message .icon {\r\n    width: 40px;\r\n    height: 40px;\r\n    border-radius: 50%;\r\n    background-size: cover;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 20px;\r\n    color: white;\r\n    background-color: #3da554;\r\n    flex-shrink: 0;\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
