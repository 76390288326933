// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-prompt-text-area {
  width: 99%;
  height: 73px;
  resize: vertical;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DataSourceDetailsTab.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".system-prompt-text-area {\r\n  width: 99%;\r\n  height: 73px;\r\n  resize: vertical;\r\n  overflow-y: auto;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
