// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-column-actions {
  width: 50px;
  display: flex;
  gap: 8px;
  margin-left: -20px;
}

.table-column-actions button {
  color: #002677;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/TableActionsColumn.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".table-column-actions {\r\n  width: 50px;\r\n  display: flex;\r\n  gap: 8px;\r\n  margin-left: -20px;\r\n}\r\n\r\n.table-column-actions button {\r\n  color: #002677;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
