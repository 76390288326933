import React, { useState } from 'react';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import './InsightsContentPage.css';  
import SplashPage from '../Splash/SplashPage';  
  
const InsightsContentPage = ({ selectedOrg }) => {  
  if (selectedOrg === undefined) {  
      return (  
        <SplashPage message="This page will be available once an Organization is selected." />
      );  
  }  

  // Mocked data  
  const insightsData = {  
    totalDataSources: 10,  
    totalFiles: 150,  
    dataSourcesPerType: {  
      local: 6,  
      AzureAiSearch: 4,  
    },  
    totalTeams: 5,  
    totalUsers: 20,  
    dataSourceNames: [  
      'Claims Data',  
      'Policyholder Demographics',  
      'Provider Contracts',  
      'Premium Calculations',  
      'Risk Assessment Reports',  
      'Mortality Tables',  
      'Morbidity Tables',  
      'Loss Ratio Analysis',  
      'Actuarial Assumptions',  
      'Trend Analysis'  
    ],  
    teamNames: [  
      'Pricing Team',  
      'Valuation Team',  
      'Risk Management',  
      'Experience Studies',  
      'Data Analytics'  
    ],  
    userNames: [  
      'Alice Actuary',  
      'Bob Benefits',  
      'Charlie Claims',  
      'Diana Demographics',  
      'Ethan Economics',  
      'Fiona Finance',  
      'George Grossman',  
      'Hannah Health',  
      'Ian Insurance',  
      'Jack Jenkins',  
      'Karen King',  
      'Liam Loss',  
      'Mia Mortality',  
      'Noah Net',  
      'Olivia Operations',  
      'Paul Pricing',  
      'Quinn Quality',  
      'Rachel Risk',  
      'Samuel Statistics',  
      'Tina Trend'  
    ],  
  };  
  
  const [showMoreDataSources, setShowMoreDataSources] = useState(false);  
  const [showMoreTeams, setShowMoreTeams] = useState(false);  
  const [showMoreUsers, setShowMoreUsers] = useState(false);  
  
  const toggleShowMore = (type) => {  
    if (type === 'dataSources') {  
      setShowMoreDataSources(!showMoreDataSources);  
    } else if (type === 'teams') {  
      setShowMoreTeams(!showMoreTeams);  
    } else if (type === 'users') {  
      setShowMoreUsers(!showMoreUsers);  
    }  
  };  
  
  return (  
      <div className="page-container">
        <div className="page-header">
          <PageTitle  
              elements={[
                { name: selectedOrg.name, route: "/" }, 
                { name: "Insights", route: "/insights" }  ]}  
          />   
        </div>

        <div className="page-upper-body">
        </div>

        <div className="page-body">
          <div className="insights-metrics">  
            <div className="insight-card">  
            <h3>Total Data Sources</h3>  
            <p>{insightsData.totalDataSources}</p>  
            <div className="scrollable-table">  
              <table>  
                <thead>  
                  <tr>  
                    <th>Data Source Name</th>  
                  </tr>  
                </thead>  
                <tbody>  
                  {insightsData.dataSourceNames.slice(0, showMoreDataSources ? insightsData.dataSourceNames.length : 5).map((name, index) => (  
                    <tr key={index}>  
                      <td>{name}</td>  
                    </tr>  
                  ))}  
                </tbody>  
              </table>  
              <a className="more-link" onClick={() => toggleShowMore('dataSources')}>  
                {showMoreDataSources ? 'See Less' : 'more'}  
              </a>  
            </div>  
          </div>  
          <div className="insight-card">  
            <h3>Total Files Uploaded</h3>  
            <p>{insightsData.totalFiles}</p>  
          </div>  
          <div className="insight-card">  
            <h3>Data Sources Per Type</h3>  
            <p>Local: {insightsData.dataSourcesPerType.local}</p>  
            <p>Azure AI Search: {insightsData.dataSourcesPerType.AzureAiSearch}</p>  
          </div>  
          <div className="insight-card">  
            <h3>Total Teams</h3>  
            <p>{insightsData.totalTeams}</p>  
            <div className="scrollable-table">  
              <table>  
                <thead>  
                  <tr>  
                    <th>Team Name</th>  
                  </tr>  
                </thead>  
                <tbody>  
                  {insightsData.teamNames.slice(0, showMoreTeams ? insightsData.teamNames.length : 5).map((name, index) => (  
                    <tr key={index}>  
                      <td>{name}</td>  
                    </tr>  
                  ))}  
                </tbody>  
              </table>  
              <a className="more-link" onClick={() => toggleShowMore('teams')}>  
                {showMoreTeams ? 'See Less' : 'more'}  
              </a>  
            </div>  
          </div>  
          <div className="insight-card">  
            <h3>Total Users</h3>  
            <p>{insightsData.totalUsers}</p>  
            <div className="scrollable-table">  
              <table>  
                <thead>  
                  <tr>  
                    <th>User Name</th>  
                  </tr>  
                </thead>  
                <tbody>  
                  {insightsData.userNames.slice(0, showMoreUsers ? insightsData.userNames.length : 5).map((name, index) => (  
                    <tr key={index}>  
                      <td>{name}</td>  
                    </tr>  
                  ))}  
                </tbody>  
              </table>  
              <a className="more-link" onClick={() => toggleShowMore('users')}>  
                {showMoreUsers ? 'See Less' : 'more'}  
              </a>  
            </div>  
          </div>  
        </div>
      </div>  
    </div>  
  );  
};  
  
export default InsightsContentPage;  
