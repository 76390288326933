import React, { useEffect } from "react";
import { userManager } from "../../services/auth";

const SilentRenew = () => {
  useEffect(() => {
    userManager.signinSilentCallback().catch((err) => {
      console.error("Silent renew error:", err);
    });
  }, []);

  return <div style={{ display: "none" }}>Token renewal in progress...</div>;
};

export default SilentRenew;
