import React, { useState, useEffect } from 'react';  
import './DatasetGitHub.css';  
import { FaTrash, FaExternalLinkAlt, FaEdit } from 'react-icons/fa';  
import DatasetGitHubPopup from './DatasetGitHubPopup';  
import { getStoredUser } from '../../services/user';  
  
const DatasetGitHub = ({ initialMappings, handleGitHubMappingsChange }) => {  
  const [repositories, setRepositories] = useState([]);  
  const [isPopupOpen, setIsPopupOpen] = useState(false);  
  const [baseSourceUrl, setBaseSourceUrl] = useState('');  
  const [gitHubRepositoryUrl, setGitHubRepositoryUrl] = useState('');  
  const [gitHubBranchName, setGitHubBranchName] = useState('');  
  const [gitHubSubDirectoryPath, setGitHubSubDirectoryPath] = useState('');  
  const [gitHubAuthToken, setGitHubAuthToken] = useState('');  
  const [fileExtensionsCommaSeparated, setFileExtensionsCommaSeparated] = useState('');  
  const [editIndex, setEditIndex] = useState(null);  
  
  useEffect(() => {  
    if (initialMappings) {  
      setRepositories(initialMappings);  
    }  
  }, [initialMappings]);  
  
  const handleAddRepository = () => {  
    const user = getStoredUser();  
    const newRepo = {  
      baseSourceUrl,  
      gitHubRepositoryUrl,  
      gitHubBranchName,  
      gitHubSubDirectoryPath,  
      gitHubAuthenticationToken: gitHubAuthToken,  
      fileExtensionsCommaSeparated,  
      addedDateUtc: new Date().toISOString(),  
      addedByUser: user.username,  
    };  
  
    let updatedRepositories;  
    if (editIndex !== null) {  
      updatedRepositories = [...repositories];  
      updatedRepositories[editIndex] = newRepo;  
      setEditIndex(null);  
    } else {  
      if (repositories.some(repo => repo.gitHubRepositoryUrl === gitHubRepositoryUrl)) {  
        alert('This GitHub Repository URL already exists.');  
        return;  
      }  
      updatedRepositories = [...repositories, newRepo];  
    }  
  
    setRepositories(updatedRepositories);  
    handleGitHubMappingsChange(updatedRepositories);  
    setBaseSourceUrl('');  
    setGitHubRepositoryUrl('');  
    setGitHubBranchName('');  
    setGitHubSubDirectoryPath('');  
    setGitHubAuthToken('');  
    setFileExtensionsCommaSeparated('');  
    setIsPopupOpen(false);  
  };  
  
  const handleRemoveRepository = (index) => {  
    const updatedRepositories = repositories.filter((_, i) => i !== index);  
    setRepositories(updatedRepositories);  
    handleGitHubMappingsChange(updatedRepositories);  
  };  
  
  const openInNewTab = (url) => {  
    window.open(url, '_blank', 'noopener,noreferrer');  
  };  
  
  const handleEditClick = (index) => {  
    const repo = repositories[index];  
    setBaseSourceUrl(repo.baseSourceUrl);  
    setGitHubRepositoryUrl(repo.gitHubRepositoryUrl);  
    setGitHubBranchName(repo.gitHubBranchName);  
    setGitHubSubDirectoryPath(repo.gitHubSubDirectoryPath);  
    setGitHubAuthToken(repo.gitHubAuthenticationToken);  
    setFileExtensionsCommaSeparated(repo.fileExtensionsCommaSeparated);  
    setEditIndex(index);  
    setIsPopupOpen(true);  
  };  
  
  const handleAddButtonClick = () => {  
    setBaseSourceUrl('');  
    setGitHubRepositoryUrl('');  
    setGitHubBranchName('');  
    setGitHubSubDirectoryPath('');  
    setGitHubAuthToken('');  
    setFileExtensionsCommaSeparated('');  
    setEditIndex(null);  
    setIsPopupOpen(true);  
  };  
  
  return (  
    <div className="github-container">  
      <div className="add-repository">  
        <button className="add-button" onClick={handleAddButtonClick}>+</button>  
      </div>  
      <table className="repositories-table">  
        <thead>  
          <tr>  
            <th>GitHub Repository URL</th>  
            <th>Subdirectory Path</th>  
            <th>Map to URL</th>  
            <th>Branch Name</th>  
            <th>File Extensions</th>  
            <th>Added By</th>  
            <th>Actions</th>  
          </tr>  
        </thead>  
        <tbody>  
          {repositories.length === 0 ? (  
            <tr>  
              <td colSpan="7">No repositories added yet</td>  
            </tr>  
          ) : (  
            repositories.map((repo, index) => (  
              <tr key={index}>  
                <td>  
                  <span className="repository-url">  
                    {repo.gitHubRepositoryUrl}{' '}  
                    <FaExternalLinkAlt className="open-icon" onClick={() => openInNewTab(repo.gitHubRepositoryUrl)} />  
                  </span>  
                </td>  
                <td>{repo.gitHubSubDirectoryPath}</td>  
                <td>  
                  <span className="repository-url">  
                    {repo.baseSourceUrl}{' '}  
                    <FaExternalLinkAlt className="open-icon" onClick={() => openInNewTab(repo.baseSourceUrl)} />  
                  </span>  
                </td>  
                <td>{repo.gitHubBranchName}</td>  
                <td>{repo.fileExtensionsCommaSeparated}</td>  
                <td>{repo.addedByUser} ({new Date(repo.addedDateUtc).toLocaleString()})</td>  
                <td className="action-buttons-cell">  
                  <FaEdit className="action-button" onClick={() => handleEditClick(index)} />  
                  <FaTrash className="action-button" onClick={() => handleRemoveRepository(index)} />  
                </td>  
              </tr>  
            ))  
          )}  
        </tbody>  
      </table>  
      <DatasetGitHubPopup  
        isOpen={isPopupOpen}  
        onClose={() => setIsPopupOpen(false)}  
        baseSourceUrl={baseSourceUrl}  
        setBaseSourceUrl={setBaseSourceUrl}  
        gitHubRepositoryUrl={gitHubRepositoryUrl}  
        setGitHubRepositoryUrl={setGitHubRepositoryUrl}  
        gitHubBranchName={gitHubBranchName}  
        setGitHubBranchName={setGitHubBranchName}  
        gitHubSubDirectoryPath={gitHubSubDirectoryPath}  
        setGitHubSubDirectoryPath={setGitHubSubDirectoryPath}  
        gitHubAuthToken={gitHubAuthToken}  
        setGitHubAuthToken={setGitHubAuthToken}  
        fileExtensionsCommaSeparated={fileExtensionsCommaSeparated}  
        setFileExtensionsCommaSeparated={setFileExtensionsCommaSeparated}  
        handleAddRepository={handleAddRepository}  
      />  
    </div>  
  );  
};  
  
export default DatasetGitHub;  
