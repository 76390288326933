import { axiosInstance } from './config';  
  
export const loginUser = async (username, password) => {  
  try {  
    const response = await fetch(`${axiosInstance.defaults.baseURL}/api/account/jwt-login`, {  
      method: 'POST',  
      headers: {  
        'Content-Type': 'application/json',  
      },  
      body: JSON.stringify({ username, password }),  
    });  
    if (!response.ok) {  
      throw new Error('Login failed');  
    }  
    const data = await response.json();  
    localStorage.setItem('jwt', data.token);
    return data.user;  
  } catch (error) {  
    console.error('Error during login:', error);  
    throw error;  
  }  
};  
