import React, { useState } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { loginUser } from '../../services/account';  
import { fetchUser } from '../../services/user';  
import './LoginPage.css';  
import { TextInput, Button } from "@uitk/react";  
  
const LoginPage = ({ setUser, setIsAuthenticated }) => {  
  const [username, setUsername] = useState('');  
  const [password, setPassword] = useState('');  
  const navigate = useNavigate();  
  
  const handleLogin = async () => {  
    try {  
      await loginUser(username, password);  
      const user = await fetchUser(username);  
      setUser(user);  
      localStorage.setItem('user', JSON.stringify(user));  
      setIsAuthenticated(true);  
      navigate('/chat');  
    } catch (error) {  
      alert('Authentication failed, please check your credentials.');  
    }  
  };  
  
  const handleKeyPress = (event) => {  
    if (event.key === 'Enter') {  
      handleLogin();  
    }  
  };  
  
  return (  
    <div className="login-container">  
      <div className="login-box">  
        <h1 className="login-title">Document Searcher</h1>  
        <div className="login-form">  
          <div className="form-group">  
            <input  
              type="text"  
              id="username"  
              placeholder="Username"  
              value={username}  
              onChange={(e) => setUsername(e.target.value)}  
              onKeyPress={handleKeyPress}  
              required  
            />  
          </div>  
          <div className="form-group">  
            <input  
              type="password"  
              id="password"  
              placeholder="Password"  
              value={password}  
              onChange={(e) => setPassword(e.target.value)}  
              onKeyPress={handleKeyPress}  
              required  
            />  
          </div>  
          <Button onPress={handleLogin}>Login</Button>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default LoginPage;  
