import React from 'react';  
import './SystemMessage.css';  
  
const SystemMessage = ({ content }) => {  
  return (  
    <div className="system-message">  
      <p>{content}</p>  
    </div>  
  );  
};  
  
export default SystemMessage;  
