import React, { useState, useCallback, useRef, useEffect } from 'react';  
import { useNavigate, useLocation } from 'react-router-dom';  
import { saveDataSource, fetchDataSourceFileMetadata } from '../../services/datasource';  
import { fetchTeams } from '../../services/team';  
import { fetchOrganization } from '../../services/organization';  
import DataSourceDetailsTab from '../../components/DataSources/DataSourceDetailsTab';  
import DatasetsTab from '../../components/DataSources/DatasetsTab';  
import DataSourceAccessControlTab from '../../components/DataSources/DataSourceAccessControlTab';  
import PageTitle from '../../components/PageTitle/PageTitle';  
import { Button } from "@uitk/react";  
import './DataSourceFormPage.css';  
  
const DataSourceFormPage = ({ user, selectedOrg }) => {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const { initialDataSource, availableTeams } = location.state || {};  
  const [newSource, setNewSource] = useState(() => {  
    const { fileMetadata, ...rest } = initialDataSource;  
    return rest;  
  });  
  const [fileAttachments, setFileAttachments] = useState([]);  
  const [removedFileNames, setRemovedFileNames] = useState([]);  
  const [allOrgUsers, setAllOrgUsers] = useState([]);  
  const [allOrgTeams, setAllOrgTeams] = useState([]);  
  const [fileMetadata, setFileMetadata] = useState([]);  
  const [isFileMetadataLoading, setIsFileMetadataLoading] = useState(true);  
  const [activeTab, setActiveTab] = useState('details');    
  const [users, setUsers] = useState(initialDataSource.users || []);  
  const [teams, setTeams] = useState(initialDataSource.teams || []);  
  const [error, setError] = useState(null);  
  const formRef = useRef(null);  
  
  useEffect(() => {  
    if (newSource.id) {  
      // DataSource already exists, fetch file metadata  
      const fetchFileMetadata = async () => {  
        try {  
          const files = await fetchDataSourceFileMetadata(newSource.id);  
          setFileMetadata(files);  
        } catch (error) {  
          console.error('Error fetching file metadata:', error);  
        } finally {  
          setIsFileMetadataLoading(false);  
        }  
      };  
      fetchFileMetadata();  
    } else {  
      // It's a new Data Source being created, no need to fetch file metadata  
      setIsFileMetadataLoading(false);  
    }  
  
    // Fetch organization data necessary for populating the form  
    const fetchOrgDetails = async () => {  
      try {  
        const org = await fetchOrganization(selectedOrg.id);  
        setAllOrgUsers(org.users);  
      } catch (error) {  
        console.error('Error fetching Organization:', error);  
      }  
    };  
    fetchOrgDetails();  
  
    // Fetch list of available Teams for the Access Control dropdown  
    const fetchOrgTeams = async () => {  
      try {  
        const teams = await fetchTeams(selectedOrg.id);  
        setAllOrgTeams(teams);  
      } catch (error) {  
        console.error('Error fetching Teams:', error);  
      }  
    };  
    fetchOrgTeams();  
  }, [newSource.id]);  
  
  const handleInputChange = useCallback((e) => {  
    const { name, value } = e.target;  
    setNewSource((prevSource) => ({ ...prevSource, [name]: value }));  
  }, []);  
  
  const handleFileAttachmentsChange = useCallback((updatedFileAttachments) => {  
    setFileAttachments((prevFileAttachments) => [...prevFileAttachments, ...updatedFileAttachments]);  
  }, []);  
  
  const handleFileAttachmentsRemove = (index) => {  
    const removedFileName = fileMetadata[index].fileName;  
    setRemovedFileNames((prev) => [...prev, removedFileName]);  
    setFileMetadata((prev) => prev.filter((_, i) => i !== index));  
  };  
  
  const handleGitHubMappingsChange = useCallback((updatedGitHubMappings) => {  
    setNewSource((prevSource) => ({  
      ...prevSource,  
      gitHubMappings: updatedGitHubMappings  
    }));  
  }, []);  
  
  const handleGitHubMappingsRemove = (index) => {  
    setNewSource((prevSource) => ({  
      ...prevSource,  
      gitHubMappings: prevSource.gitHubMappings.filter((_, i) => i !== index)  
    }));  
  };  
  
  const handleSave = async () => {  
    const updatedSource = {  
      ...newSource,  
      users,  
      teams,  
      organizationId: selectedOrg.id,  
      removedFileNames,  
    };  
  
    try {  
      await saveDataSource(updatedSource, fileAttachments);  
      navigate('/datasources');  
    } catch (error) {  
      console.error('Error adding/updating data source:', error);  
    }  
  };  
  
  return (  
    <div className="page-container" ref={formRef}>  
      {error && <div className="error-message">{error}</div>}  
      <PageTitle  
        elements={[  
          { name: selectedOrg.name, route: "/" },  
          { name: "Data Sources", route: "/datasources" },  
          { name: initialDataSource.id ? newSource.name : 'New', route: "#" }  
        ]}  
      />  
      <div className="tabs">  
        <div className={`tab ${activeTab === 'details' ? 'active' : ''}`} onClick={() => setActiveTab('details')}>Details</div>  
        <div className={`tab ${activeTab === 'datasets' ? 'active' : ''}`} onClick={() => setActiveTab('datasets')}>Datasets</div>  
        <div className={`tab ${activeTab === 'accessControl' ? 'active' : ''}`} onClick={() => setActiveTab('accessControl')}>Access Control</div>  
      </div>  
      <div className={`tab-content ${activeTab === 'details' ? 'active' : ''}`}>  
        <DataSourceDetailsTab newSource={newSource} handleInputChange={handleInputChange} />  
      </div>  
      <div className={`tab-content ${activeTab === 'datasets' ? 'active' : ''}`}>  
        <DatasetsTab  
          dataSourceId={newSource.id}  
          fileMetadata={fileMetadata}  
          isFileMetadataLoading={isFileMetadataLoading}  
          handleFileAttachmentsChange={handleFileAttachmentsChange}  
          handleFileAttachmentsRemove={handleFileAttachmentsRemove}  
          gitHubMappings={newSource.gitHubMappings}  
          handleGitHubMappingsChange={handleGitHubMappingsChange}  
          handleGitHubMappingsRemove={handleGitHubMappingsRemove}  
        />  
      </div>  
      <div className={`tab-content ${activeTab === 'accessControl' ? 'active' : ''}`}>  
        <DataSourceAccessControlTab  
          users={users}  
          setUsers={setUsers}  
          teams={teams}  
          setTeams={setTeams}  
          currentUser={user}  
          availableUsers={allOrgUsers}  
          availableTeams={allOrgTeams}  
        />  
      </div>  
      <div className="button-container">  
        <Button onPress={handleSave}>Save</Button>  
        <Button onPress={() => navigate('/datasources')}>Cancel</Button>  
      </div>  
    </div>  
  );  
};  
  
export default DataSourceFormPage;  
