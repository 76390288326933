import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "@uitk/react";
import "./TableActionsColumn.css";

const TableActionsColumn = ({ id, name, onEdit, onDelete }) => {
  return (
    <div className="table-column-actions">
      {/* Render the Edit button only if onEdit is provided */}
      {onEdit && (
        <Button variant="tertiary" onPress={() => onEdit(id)} aria-label={`Edit ${name}`} size="s">
          <FaEdit />
        </Button>
      )}

      {/* Render the Delete button only if onDelete is provided */}
      {onDelete && (
        <Button variant="tertiary" onPress={() => onDelete({ id, name })} aria-label={`Delete ${name}`} size="s">
          <FaTrash />
        </Button>
      )}
    </div>
  );
};

export default TableActionsColumn;
