import React from 'react';  
import './DatasetGitHubPopup.css';  
import { FaTimes } from 'react-icons/fa';  
import { Button } from "@uitk/react";  
  
const DatasetGitHubPopup = ({  
  isOpen,  
  onClose,  
  baseSourceUrl,  
  setBaseSourceUrl,  
  gitHubRepositoryUrl,  
  setGitHubRepositoryUrl,  
  gitHubBranchName,  
  setGitHubBranchName,  
  gitHubSubDirectoryPath,  
  setGitHubSubDirectoryPath,  
  gitHubAuthToken,  
  setGitHubAuthToken,  
  fileExtensionsCommaSeparated,  
  setFileExtensionsCommaSeparated,  
  handleAddRepository,  
}) => {  
  const isValidGitUrl = (string) => {  
    try {  
      const url = new URL(string);  
      return url.pathname.endsWith('.git');  
    } catch (_) {  
      return false;  
    }  
  };  
  
  const handleSave = () => {  
    if (!isValidGitUrl(gitHubRepositoryUrl)) {  
      alert('Please enter a valid GitHub Repository URL ending with .git.');  
      return;  
    }  
    handleAddRepository();  
  };  
  
  if (!isOpen) return null;  
  
  return (  
    <div className="popup-overlay">  
      <div className="dataset-github-popup-content">  
        <button className="close-button" onClick={onClose}>  
          <FaTimes />  
        </button>  
        <h2>Add GitHub Repository</h2>  
        <div className="form-group">  
          <label>GitHub Repository URL</label>  
          <input type="text" value={gitHubRepositoryUrl} onChange={(e) => setGitHubRepositoryUrl(e.target.value)} />  
        </div>  
        <div className="form-group">  
          <label>Subdirectory Path</label>  
          <input type="text" value={gitHubSubDirectoryPath} onChange={(e) => setGitHubSubDirectoryPath(e.target.value)} />  
        </div>  
        <div className="form-group">  
          <label>Map to URL</label>  
          <input type="text" value={baseSourceUrl} onChange={(e) => setBaseSourceUrl(e.target.value)} />  
        </div>  
        <div className="form-group">  
          <label>GitHub Branch Name</label>  
          <input type="text" value={gitHubBranchName} onChange={(e) => setGitHubBranchName(e.target.value)} />  
        </div>  
        <div className="form-group">  
          <label>Included File Extensions (comma separated)</label>  
          <input type="text" value={fileExtensionsCommaSeparated} onChange={(e) => setFileExtensionsCommaSeparated(e.target.value)} />  
        </div>  
        <div className="form-group">  
          <label>GitHub Authentication Token</label>  
          <input type="text" value={gitHubAuthToken} onChange={(e) => setGitHubAuthToken(e.target.value)} />  
        </div>  
        <div className="button-container">  
          <Button onPress={handleSave}>Save</Button>  
          <Button onPress={onClose}>Cancel</Button>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default DatasetGitHubPopup;  
