import React, { useState, useEffect } from 'react';  
import { getStoredOrg, fetchOrganization } from '../../services/organization';  
import './DataSourceAccessControlTab.css';  
import { FaTrash } from 'react-icons/fa';  
import { Button, Dropdown, Tooltip } from "@uitk/react";  
import { InfoIcon } from "@uitk/react-icons";  
import styled, { css } from "styled-components";  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const DataSourceAccessControlTab = ({ users, setUsers, teams, setTeams, currentUser, availableUsers, availableTeams }) => {  
    const [selectedTeam, setSelectedTeam] = useState(null);  
    const [selectedUser, setSelectedUser] = useState(null);  
    const [filteredTeams, setFilteredTeams] = useState([]);  
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
    const [entityToDelete, setEntityToDelete] = useState(null);  
  
    useEffect(() => {  
        const filterTeams = () => {  
            const filtered = availableTeams.filter(team => !teams.some(t => t.id === team.id));  
            setFilteredTeams(filtered);  
        };  
        filterTeams();  
    }, [teams, availableTeams]);  
  
    useEffect(() => {  
        const fetchOrgUsers = async () => {  
            try {  
                const selectedOrg = await getStoredOrg();  
                const org = await fetchOrganization(selectedOrg.id);  
                setOrganizationUsers(org.users);  
            } catch (error) {  
                console.error('Error fetching organization users:', error);  
            }  
        };  
        fetchOrgUsers();  
    }, []);  
  
    const handleAddTeam = () => {  
        if (selectedTeam) {  
            const team = availableTeams.find(team => team.id === parseInt(selectedTeam.value));  
            setTeams([...teams, { ...team, permission: 'Read' }]);  
            setSelectedTeam(null);  
        }  
    };  
  
    const handleAddUser = () => {  
        if (selectedUser) {  
            const user = availableUsers.find(user => user.username.toLowerCase() === selectedUser.value.toLowerCase());  
            setUsers([...users, { ...user, permission: 'Read' }]);  
            setSelectedUser(null);  
        }  
    };  
  
    const handlePermissionChange = (index, type, value) => {  
        if (type === 'User') {  
            const updatedUsers = [...users];  
            updatedUsers[index].permission = value;  
            setUsers(updatedUsers);  
        } else {  
            const updatedTeams = [...teams];  
            updatedTeams[index].permission = value;  
            setTeams(updatedTeams);  
        }  
    };  
  
    const StyledInfoFilled = styled(InfoIcon)`  
        ${({ theme }) => css`  
            fill: ${theme.UitkTooltipColorIndicator};  
        `}  
    `;  
  
    const handleRemoveUserClick = (index, username) => {  
        setEntityToDelete({ type: 'User', entityName: username, index });  
        setIsDeleteDialogOpen(true);  
    };  
  
    const handleRemoveTeamClick = (index, name) => {  
        setEntityToDelete({ type: 'Team', entityName: name, index });  
        setIsDeleteDialogOpen(true);  
    };  
  
    const handleDeleteDialogClose = () => {  
        setIsDeleteDialogOpen(false);  
    };  
  
    const handleDeleteDialogConfirm = () => {  
        if (entityToDelete.type === 'User') {  
            setUsers(users.filter((_, i) => i !== entityToDelete.index));  
        } else if (entityToDelete.type === 'Team') {  
            setTeams(teams.filter((_, i) => i !== entityToDelete.index));  
        }  
        setIsDeleteDialogOpen(false);  
        setEntityToDelete(null);  
    };  
  
    const filteredUsers = availableUsers.filter(orgUser => !users.some(user => user.username.toLowerCase() === orgUser.username.toLowerCase()));  
    const teamItems = filteredTeams.map(team => ({ id: team.id.toString(), label: team.name, value: team.id.toString() }));  
    const userItems = filteredUsers.map(user => ({ id: user.username, label: `${user.firstName} ${user.lastName} (${user.username})`, value: user.username }));  
  
    return (  
        <div className="access-control-section">  
            <div className="access-control-subsection">  
                <div className="title-with-tooltip">  
                    <h3>Users</h3>  
                    <Tooltip placement="right-start" content="Users can be granted access to this Data Source. Users must first be added to the Organization by a System Admin.">  
                        <StyledInfoFilled size="16" />  
                    </Tooltip>  
                </div>  
                <div className="dropdown-add-container">  
                    <Dropdown  
                        type="single"  
                        items={userItems}  
                        value={selectedUser}  
                        onChange={setSelectedUser}  
                    />  
                    <Button onPress={handleAddUser}>Add</Button>  
                </div>  
                <table className="access-control-table">  
                    <tbody>  
                        {users.map((user, index) => (  
                            <tr key={index}>  
                                <td>  
                                    {`${user.firstName} ${user.lastName} (${user.username})`}  
                                    <div className="permission-container">  
                                        <select value={user.permission} onChange={(e) => handlePermissionChange(index, 'User', e.target.value)}>  
                                            <option value="Read">Read</option>  
                                            <option value="Write">Write</option>  
                                        </select>  
                                        <span onClick={() => handleRemoveUserClick(index, user.username)}>  
                                            <FaTrash className="action-button" />  
                                        </span>  
                                    </div>  
                                </td>  
                            </tr>  
                        ))}  
                    </tbody>  
                </table>  
            </div>  
            <div className="access-control-subsection-team">  
                <div className="title-with-tooltip">  
                    <h3>Teams</h3>  
                    <Tooltip placement="right-start" content="Multiple Organization Users can be granted access to this Data Source by adding them to a Team and granting the Team access to this Data Source.">  
                        <StyledInfoFilled size="16" />  
                    </Tooltip>  
                </div>  
                <div className="dropdown-add-container">  
                    <Dropdown  
                        type="single"  
                        items={teamItems}  
                        value={selectedTeam}  
                        onChange={setSelectedTeam}  
                    />  
                    <Button onPress={handleAddTeam}>Add</Button>  
                </div>  
                <table className="table">  
                    <tbody>  
                        {teams.length === 0 ? (  
                            <tr>  
                                <td>No teams linked yet</td>  
                            </tr>  
                        ) : (  
                            teams.map((team, index) => (  
                                <tr key={index}>  
                                    <td>  
                                        {team.name}  
                                        <div className="permission-container">  
                                            <select value={team.permission} onChange={(e) => handlePermissionChange(index, 'Team', e.target.value)}>  
                                                <option value="Read">Read</option>  
                                                <option value="Write">Write</option>  
                                            </select>  
                                            <span onClick={() => handleRemoveTeamClick(index, team.name)}>  
                                                <FaTrash className="action-button" />  
                                            </span>  
                                        </div>  
                                    </td>  
                                </tr>  
                            ))  
                        )}  
                    </tbody>  
                </table>  
            </div>  
            {isDeleteDialogOpen && (  
                <ConfirmationDialog  
                    open={isDeleteDialogOpen}  
                    onClose={handleDeleteDialogClose}  
                    onConfirm={handleDeleteDialogConfirm}  
                    message={`Are you sure you want to remove ${entityToDelete?.type} '${entityToDelete?.entityName}'? The removal will take effect once the Data Source is saved.`}  
                    title={`Remove ${entityToDelete?.type}?`}  
                />  
            )}  
        </div>  
    );  
};  
  
export default DataSourceAccessControlTab;  
