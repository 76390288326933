// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-spin {  
  animation: spin 1s linear infinite;  
}  
@keyframes spin {  
  0% { transform: rotate(0deg); }  
  100% { transform: rotate(360deg); }  
}  
`, "",{"version":3,"sources":["webpack://./src/pages/DataSources/DataSourcesContentPage.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;AACA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".icon-spin {  \r\n  animation: spin 1s linear infinite;  \r\n}  \r\n@keyframes spin {  \r\n  0% { transform: rotate(0deg); }  \r\n  100% { transform: rotate(360deg); }  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
