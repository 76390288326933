import React from 'react';  
import UserMessage from './UserMessage';  
import BotMessage from './BotMessage';  
import SystemMessage from './SystemMessage';  
  
const ChatMessage = ({ role, content, sources, dataSourceId, isTyping, handleReSubmit }) => {  
    if (role === 'user') {  
        return <UserMessage content={content} />;  
    } else if (role === 'assistant') {  
        return <BotMessage content={content} sources={sources} dataSourceId={dataSourceId} isTyping={isTyping} handleReSubmit={handleReSubmit} />;  
    } else if (role === 'system') {  
        return <SystemMessage content={content} />;  
    }  
    return null;  
};  
  
export default ChatMessage;  
