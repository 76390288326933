import React, { useState } from 'react';  
import { Routes, Route, Navigate } from 'react-router-dom';  
import ChatContentPage from '../../pages/Chat/ChatContentPage';  
import DataSourcesContentPage from '../../pages/DataSources/DataSourcesContentPage';  
import DataSourceFormPage from '../../pages/DataSources/DataSourceFormPage';  
import InsightsContentPage from '../../pages/Insights/InsightsContentPage';  
import LoginPage from '../../pages/Login/LoginPage';  
import TeamsContentPage from '../../pages/Teams/TeamsContentPage';  
import TeamFormPage from '../../pages/Teams/TeamFormPage';  
import UsersContentPage from '../../pages/Users/UsersContentPage';  
import UserFormPage from '../../pages/Users/UserFormPage';  
import OrganizationsContentPage from '../../pages/Organizations/OrganizationsContentPage';  
import OrganizationFormPage from '../../pages/Organizations/OrganizationFormPage';  
import ProfilePopup from '../../components/Profile/ProfilePopup';  
import DocumentationPage from '../../pages/Resources/DocumentationPage';  
import SupportPage from '../../pages/Resources/SupportPage';  
  
const AppRoutes = ({ user, dataSources, selectedOrg, setUser }) => {  
    const [isProfileOpen, setIsProfileOpen] = useState(false);  
    const handleProfileClose = () => {  
        setIsProfileOpen(false);  
    };  
  
    return (  
        <>  
            <Routes>  
                <Route  
                    exact  
                    path="/"  
                    element={user ? <Navigate to="/chat" /> : <LoginPage setUser={setUser} />}  
                />  
                <Route  
                    path="/chat"  
                    element={  
                        user ? (  
                            <ChatContentPage  
                                dataSources={dataSources}  
                                selectedOrg={selectedOrg}  
                                user={user}  
                            />  
                        ) : (  
                            <LoginPage setUser={setUser} />  
                        )  
                    }  
                />  
                {user.isSystemAdmin ? (  
                    <>  
                        <Route path="/users" element={<UsersContentPage selectedOrg={selectedOrg} />} />  
                        <Route path="/users/new" element={<UserFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/users/edit/:username" element={<UserFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/organizations" element={<OrganizationsContentPage selectedOrg={selectedOrg} />} />  
                        <Route path="/organizations/new" element={<OrganizationFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/organizations/edit/:id" element={<OrganizationFormPage user={user} selectedOrg={selectedOrg} />} />                          
                    </>  
                ) : (  
                    <Route path="*" element={<Navigate to="/" />} />  
                )}  
                {user.isSystemAdmin || user.isOrganizationOwner ? (  
                    <>  
                        <Route path="/teams" element={<TeamsContentPage selectedOrg={selectedOrg} />} />  
                        <Route path="/teams/new" element={<TeamFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/teams/edit/:id" element={<TeamFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/insights" element={<InsightsContentPage selectedOrg={selectedOrg} />} />  
                    </>  
                ) : (  
                    <Route path="*" element={<Navigate to="/" />} />  
                )}  
                {user.isSystemAdmin || user.isOrganizationOwner || user.canViewDataSourcesPage ? (  
                    <>  
                        <Route path="/datasources" element={<DataSourcesContentPage dataSources={dataSources} user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/datasources/new" element={<DataSourceFormPage user={user} selectedOrg={selectedOrg} />} />  
                        <Route path="/datasources/edit/:id" element={<DataSourceFormPage user={user} selectedOrg={selectedOrg} />} />                          
                    </>  
                ) : (  
                    <Route path="*" element={<Navigate to="/" />} />  
                )}  
                <Route path="/guide" element={user ? <DocumentationPage /> : <LoginPage setUser={setUser} />} />  
                <Route path="/support" element={user ? <SupportPage /> : <LoginPage setUser={setUser} />} />  
                <Route path="/profile" element={user ? <ProfilePopup user={user} onClose={handleProfileClose} /> : <LoginPage setUser={setUser} />} />  
                <Route path="*" element={<Navigate to="/chat" />} />  
            </Routes>  
            {isProfileOpen && <ProfilePopup user={user} onClose={handleProfileClose} />}  
        </>  
    );  
};  
  
export default AppRoutes;  
