import React, { useState, useEffect } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { fetchUsers, deleteUser } from '../../services/user';  
import { FaEdit, FaTrash } from 'react-icons/fa';  
import './UsersContentPage.css';  
import { Button } from "@uitk/react";  
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';  
  
const UsersContentPage = () => {  
    const [users, setUsers] = useState([]);  
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
    const [userToDelete, setUserToDelete] = useState(null);  
    const navigate = useNavigate();  
  
    const fetchAllUsers = async () => {  
        const users = await fetchUsers();  
        setUsers(users);  
    };  
  
    useEffect(() => {  
        fetchAllUsers();  
    }, []);  
  
    const handleEditClick = (username) => {  
        navigate(`/users/edit/${username}`);  
    };  
      
    const handleDeleteClick = (user) => {  
        setUserToDelete(user);  
        setIsDeleteDialogOpen(true);  
    };  
    
    const handleDeleteDialogClose = () => {  
        setIsDeleteDialogOpen(false);  
    };  
    
    const handleDeleteDialogConfirm = async () => {
        await handleDelete(userToDelete.username);  
        setIsDeleteDialogOpen(false);  
        setUserToDelete(null);  
    };  

    const handleDelete = async (username) => {  
        await deleteUser(username);  
        setUsers(users.filter(user => user.username !== username));  
    };  
  
    return (  
        <div className="page-container">  
            <div className="page-header">  
                <h2>Users</h2>  
                <div className="page-title-description">Individuals who interact with the system, having roles that define their access levels.</div>  
            </div>  
            <div className="page-upper-body">  
                <Button onPress={() => navigate('/users/new')}>Add new</Button>
            </div>  
            <div className="page-body">  
                <table>  
                    <thead>  
                        <tr>  
                            <th>Username</th>  
                            <th>Full Name</th>  
                            <th style={{ width: '90px' }}>Actions</th>  
                        </tr>  
                    </thead>  
                    <tbody>  
                        {users.map((user) => (  
                            <tr key={user.username}>  
                                <td>{user.username}</td>  
                                <td>{`${user.firstName} ${user.lastName}`}</td>  
                                <td className="action-buttons-cell">  
                                    <button className="action-button" onClick={() => handleEditClick(user.username)}>  
                                        <FaEdit />  
                                    </button>  
                                    <button className="action-button" onClick={() => handleDeleteClick(user)}>  
                                        <FaTrash />  
                                    </button>  
                                </td>  
                            </tr>  
                        ))}  
                    </tbody>  
                </table>  
            </div>  
        {isDeleteDialogOpen && (  
            <ConfirmationDialog  
            open={isDeleteDialogOpen}  
            onClose={handleDeleteDialogClose}  
            onConfirm={handleDeleteDialogConfirm}  
            message={`Are you sure you want to delete User '${userToDelete.username}'? This action cannot be undone.`}
            title="Delete User?"
            />  
        )}
        </div>  
    );  
};  
  
export default UsersContentPage;  
