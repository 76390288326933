// ./client/src/components/DataSources/DatasetsTab.js  
import React, { useState } from 'react';  
import DatasetFiles from './DatasetFiles';  
import DatasetGitHub from './DatasetGitHub';  
import './DatasetsTab.css';  
import { Button } from "@uitk/react";  
  
const DatasetsTab = ({ dataSourceId, fileMetadata, isLoading, handleFileAttachmentsChange, handleFileAttachmentsRemove, gitHubMappings, handleGitHubMappingsChange, handleGitHubMappingsRemove }) => {  
  const [activeSubTab, setActiveSubTab] = useState('files');  
  
  return (  
    <div className="datasets-tab">  
      <div className="subtabs">  
        <div  
          className={`subtab ${activeSubTab === 'files' ? 'active' : ''}`}  
          onClick={() => setActiveSubTab('files')}  
        >  
          Files  
        </div>  
        <div  
          className={`subtab ${activeSubTab === 'github' ? 'active' : ''}`}  
          onClick={() => setActiveSubTab('github')}  
        >  
          GitHub
        </div>  
      </div>  
      {activeSubTab === 'files' && (  
        <div className="section-container">  
          <DatasetFiles  
            dataSourceId={dataSourceId}  
            fileMetadata={fileMetadata}  
            isLoading={isLoading}
            handleFileAttachmentsChange={handleFileAttachmentsChange}  
            handleFileAttachmentsRemove={handleFileAttachmentsRemove}  
          />  
        </div>  
      )}        
      {activeSubTab === 'github' && (  
        <div className="section-container">  
          <DatasetGitHub  
            initialMappings={gitHubMappings}  
            handleGitHubMappingsChange={handleGitHubMappingsChange}  
            handleGitHubMappingsRemove={handleGitHubMappingsRemove}  
          />  
        </div>  
      )}  
    </div>  
  );  
};  
  
export default DatasetsTab;  
