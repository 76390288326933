import { UserManager, WebStorageStateStore } from "oidc-client";

const oidcConfig = {
  authority: process.env.OIDC_AUTHORITY,
  client_id: process.env.OIDC_CLIENT_ID,
  response_type: "code",
  code_challenge_method: "S256",
  redirect_uri: process.env.OIDC_REDIRECT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.OIDC_SILENT_REDIRECT_URI,
  scope: "openid profile address email phone",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    acr_values: process.env.OIDC_ACR_VALUES,
  },
};

export const userManager = new UserManager(oidcConfig);

export function login() {
  return userManager.signinRedirect();
}

export function getUser() {
  return userManager.getUser();
}

export function handleCallback() {
  return userManager.signinRedirectCallback();
}