import React from "react";  
import { Button, Dialog, Text } from "@uitk/react";  
  
const ConfirmationDialog = ({ open, onClose, onConfirm, message, title }) => {  
  return (  
    <Dialog open={open} title={title} titleAs="h1" onClose={onClose}>  
      <Dialog.Body>  
        <Text>{message}</Text>  
      </Dialog.Body>  
      <Dialog.Actions>  
        <Button onPress={onConfirm}>Confirm</Button>  
        <Button variant="secondary" onPress={onClose}>Cancel</Button>  
      </Dialog.Actions>  
    </Dialog>  
  );  
};  
  
export default ConfirmationDialog;  
