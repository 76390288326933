// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DatasetsTab.css */  
.datasets-tab {  
  width: 100%;  
}  
  
.subtabs {  
  display: flex;  
  margin-bottom: 20px;  
}  
  
.subtab {  
  padding: 10px;  
  cursor: pointer;  
  border-bottom: 2px solid transparent;  
}  
  
.subtab.active {  
  border-bottom: 2px solid #002677;  
}  
  
.section-container {  
  margin-bottom: 20px;  
  padding: 10px;  
  border: 1px solid #ddd;  
  border-radius: 5px;  
  background-color: #f9f9f9;  
}  
  
.section-title {  
  font-weight: bold;  
  margin-bottom: 10px;  
}  
`, "",{"version":3,"sources":["webpack://./src/components/DataSources/DatasetsTab.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["/* DatasetsTab.css */  \r\n.datasets-tab {  \r\n  width: 100%;  \r\n}  \r\n  \r\n.subtabs {  \r\n  display: flex;  \r\n  margin-bottom: 20px;  \r\n}  \r\n  \r\n.subtab {  \r\n  padding: 10px;  \r\n  cursor: pointer;  \r\n  border-bottom: 2px solid transparent;  \r\n}  \r\n  \r\n.subtab.active {  \r\n  border-bottom: 2px solid #002677;  \r\n}  \r\n  \r\n.section-container {  \r\n  margin-bottom: 20px;  \r\n  padding: 10px;  \r\n  border: 1px solid #ddd;  \r\n  border-radius: 5px;  \r\n  background-color: #f9f9f9;  \r\n}  \r\n  \r\n.section-title {  \r\n  font-weight: bold;  \r\n  margin-bottom: 10px;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
