// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-org-container {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  height: 100vh;  
  text-align: center;  
  padding: 20px;  
  box-sizing: border-box;  
  background-color: #f0f0f0;  
}  
`, "",{"version":3,"sources":["webpack://./src/pages/Splash/SplashPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B","sourcesContent":[".no-org-container {  \r\n  display: flex;  \r\n  justify-content: center;  \r\n  align-items: center;  \r\n  height: 100vh;  \r\n  text-align: center;  \r\n  padding: 20px;  \r\n  box-sizing: border-box;  \r\n  background-color: #f0f0f0;  \r\n}  \r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
