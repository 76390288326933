import React from 'react';  
import { FaTimes } from 'react-icons/fa';  
import './ProfilePopup.css';  
  
const ProfilePopup = ({ user, onClose }) => {    
  return (  
    <div className="popup-overlay">  
      <div className="profile-popup-content">  
        <button className="close-button" onClick={onClose}>  
          <FaTimes />  
        </button>  
        <div className="profile-container">  
          <h2>Profile</h2>  
          <div className="profile-field">  
            <label>Username:</label>  
            <input type="text" value={user.username} readOnly />  
          </div>  
          <div className="profile-field">  
            <label>First Name:</label>  
            <input type="text" value={user.firstName} readOnly />  
          </div>  
          <div className="profile-field">  
            <label>Last Name:</label>  
            <input type="text" value={user.lastName} readOnly />  
          </div>  
          <div className="profile-field">  
            <label>Email:</label>  
            <input type="text" value={user.email} readOnly />  
          </div>  
          <div className="profile-field">  
            <div className="permissions-section">  
              <h3>Current Access</h3>  
              <table className="permissions-table">  
                <thead>  
                  <tr>  
                    <th>Name</th>  
                    <th>Role</th>  
                    <th>Teams</th>  
                    <th>Data Source Permissions</th>  
                  </tr>  
                </thead>  
                <tbody>  
                  {user.organizations.map((org) => (  
                    <tr key={org.id}>  
                      <td>{org.name}</td>  
                      <td>{org.role}</td>  
                      <td>{org.teams.map((team) => team.name).join(', ')}</td>  
                      <td>  
                        {org.dataSources.map((ds) => `${ds.dataSourceName} (${ds.permission})`).join(', ')}  
                      </td>  
                    </tr>  
                  ))}  
                </tbody>  
              </table>  
            </div>  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default ProfilePopup;  
