import axios from "axios";
import { userManager } from "./auth";

const API_BASE_URL = process.env.API_BASE_URL || "http://localhost:5121";

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const oidcUserStorageKey = "oidc.user:https://authgateway3-dev.entiam.uhg.com:Reg3Dev_stepwise_spa";
    const oidcUserStr = localStorage.getItem(oidcUserStorageKey);
    if (oidcUserStr) {
      try {
        const oidcUser = JSON.parse(oidcUserStr);
        if (oidcUser?.access_token) {
          config.headers.Authorization = `Bearer ${oidcUser?.access_token}`;
        }
        if (oidcUser?.profile) {
          config.headers["OIDC-User-Email"] = oidcUser.profile.email ?? "";
        }
        if (oidcUser?.profile?.msad_groups) {
          config.headers["OIDC-User-MSAD-Groups"] = JSON.stringify(oidcUser.profile.msad_groups);
        }
      } catch (error) {
        console.error("Failed to parse OIDC user data:", error);
      }
    }

    const userStorageKey = "user";
    const userStr = localStorage.getItem(userStorageKey);
    if (userStr) {
      try {
        const user = JSON.parse(userStr);
        if (user) {
          config.headers["OIDC-User-IsSystemAdmin"] = user.isSystemAdmin ?? "";
        }
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return userManager
        .signinSilent()
        .then(() => {
          // Retry the original request so the user’s action (e.g., form submission or data retrieval) can succeed transparently after the token is renewed.
          // Without a retry, the user receives a 401 response and must manually reattempt whatever triggered the expired token error.
          const originalRequest = error.config;
          originalRequest._retry = true;
          return axiosInstance(originalRequest);
        })
        .catch(() => {
          localStorage.removeItem("user");
          localStorage.removeItem("oidc.user:https://authgateway3-dev.entiam.uhg.com:Reg3Dev_stepwise_spa");
          window.location.href = "/";
        });
    }

    return Promise.reject(error);
  }
);
