import { axiosInstance } from './config';  
  
export const fetchDataSources = async (orgId) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/all/${orgId}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching data sources:', error);  
        return [];  
    }  
};  
  
export const fetchSimplifiedDataSources = async (orgId) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/all-simplified/${orgId}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching data sources:', error);  
        return [];  
    }  
};  
  
export const fetchDataSource = async (id) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/${id}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching data source:', error);  
        throw error;  
    }  
};  
  
export const saveDataSource = async (dataSource, fileAttachments) => {  
    try {  
        const formData = new FormData();  
        formData.append('dto', JSON.stringify(dataSource));  
        fileAttachments.forEach((file) => {  
            formData.append('files', file);  
        });  
        const config = {  
            headers: { 'Content-Type': 'multipart/form-data' },  
        };  
        const organizationId = dataSource.organizationId;  
          
        if (dataSource.id) {  
            await axiosInstance.put(`/api/datasource/${organizationId}/${dataSource.id}`, formData, config);  
        } else {  
            await axiosInstance.post(`/api/datasource/${organizationId}`, formData, config);  
        }  
    } catch (error) {  
        console.error('Error saving data source:', error);  
        throw error;  
    }  
};  

export const deleteDataSource = async (organizationId, dataSourceId) => {  
    try {  
        await axiosInstance.delete(`/api/datasource/${organizationId}/${dataSourceId}`);  
    } catch (error) {  
        console.error('Error deleting data source:', error);  
        throw error;  
    }  
};  
  
export const fetchDocument = async (dataSourceId, fileName) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/file/${dataSourceId}/${fileName}`, {  
            responseType: 'blob',  
        });  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching document:', error);  
        throw error;  
    }  
};  
  
export const fetchDataSourceFileMetadata = async (dataSourceId) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/file-metadata/${dataSourceId}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching file metadata:', error);  
        throw error;  
    }  
};  

export const fetchDataSourceFileIndexDocuments = async (dataSourceId, fileName) => {  
    try {  
        const response = await axiosInstance.get(`/api/datasource/file-index-documents/${dataSourceId}/${fileName}`);  
        return response.data;  
    } catch (error) {  
        console.error('Error fetching file index documents:', error);  
        throw error;  
    }  
};  
